/* eslint-disable default-case */
import React from 'react'
import connect from "react-redux/es/connect/connect"
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import OverridableAttendanceTypesDialog from "apps/admin/components/OverridableAttendanceTypesDialog";

import {
  findOneBy,
} from '@relativity/js-util'

import {
  bellRulesEditDone,
  bellRulesEditUpdateProperty,
  bellRulesEditSave,
  overridableAtTypesEdit
} from 'redux/admin/Actions'

import DialogTitle from 'common/components/dialog/DialogTitle'
import DialogContent from 'common/components/dialog/DialogContent'
import DialogActions from 'common/components/dialog/DialogActions'
import FancySelect from 'common/components/FancySelect'
import BellRuleSVG from "common/components/BellRuleSVG"

import styles from './style'
import {FullTextFieldD} from "common/components/FullTextField";
import {Divider} from "@material-ui/core";

import {minuteList, presentMinuteList} from './objects'
import {paModeList, yesNoBoolList, AT_TYPE_ID_NOT_SET, AT_TYPE_ID_PRESENT} from "redux/constants";
import Typography from "@material-ui/core/Typography";
import ToggleIconButton from "common/components/ToggleIconButton";

const IS_OUT_OF_BUILDING_PROPAGATION_ENABLED = 'isOutOfBuildingPropagationEnabled';

class BellRulesDialog extends React.Component {

  guessTypes = () => {
    this.props.atTypeChoicesForSubPeriods.general.forEach(item => {
      switch(item.name) {
        case 'AE' :
          this.props.bellRulesEditUpdateProperty({
            propertyName:'atTypeAbsentExcusedId',
            val:item.id
          })
          break
        case 'AU' :
          this.props.bellRulesEditUpdateProperty({
            propertyName:'atTypeAbsentId',
            val:item.id
          })
          break
        case 'OOB' :
          this.props.bellRulesEditUpdateProperty({
            propertyName:'atTypeOutOfBuildingId',
            val:item.id
          })
          break
        /*case 'OOL' :
          this.props.bellRulesEditUpdateProperty({
            propertyName:'atTypeOutId',
            val:item.id
          })
          break */
        case 'TE' :
          this.props.bellRulesEditUpdateProperty({
            propertyName:'atTypeTardyExcusedId',
            val:item.id
          })
          break
        case 'TU' :
          this.props.bellRulesEditUpdateProperty({
            propertyName:'atTypeTardyId',
            val:item.id
          })
          break
      }
    })
  }

  handleOutOfBuildingPropagationUpdateProperty = ({ propertyName, val }) => {
    if (propertyName === IS_OUT_OF_BUILDING_PROPAGATION_ENABLED && val === true) {
      if (!this.props.bellSchedule.atTypeOutOfBuildingId) {
        alert('Please select "Out of Building" type before setting this option to "Yes"');
        return; 
      }
    }

    this.props.bellRulesEditUpdateProperty({ propertyName, val });
  };

  render() {
    if(!this.props.bellSchedule) return null

    const {
      classes,
      bellRulesEditDone,
      bellRulesEditUpdateProperty,
      bellRulesEditSave,
      bellSchedule,
      atReasonChoicesForSubPeriods,
      atTypeChoicesForSubPeriods,
      positiveAttendanceIsEnabled,
      overridableAtTypesEdit,
      atTypes,
      currentFacilityId
       } = this.props

    const facilityOverridableAtTypes = atTypes.filter(atType => atType.facilityId === currentFacilityId && atType.kioskCanOverride);
    const bellScheduleAtTypeId = bellSchedule.atTypePresentId === AT_TYPE_ID_NOT_SET ? AT_TYPE_ID_PRESENT : bellSchedule.atTypePresentId;
    
    return (
      <Dialog
        onClose={bellRulesEditDone}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={bellRulesEditDone}>
          Period Rules
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <Grid container>
            <Grid item xs={4}>
              <Grid container>
                {positiveAttendanceIsEnabled ?
                  <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Early / On-Time'
                    propertyName='atTypePresentId'
                    currentItem={findOneBy(atTypeChoicesForSubPeriods.present, 'id', bellScheduleAtTypeId )}
                    items={atTypeChoicesForSubPeriods.present}
                    nameAtr='displayName'
                  />
                </Grid>
                  :
                  null
                }
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Tardy Type'
                    propertyName='atTypeTardyId'
                    currentItem={findOneBy(atTypeChoicesForSubPeriods.tardy, 'id', bellSchedule.atTypeTardyId )}
                    items={atTypeChoicesForSubPeriods.tardy}
                    nameAtr='displayName'
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Absent Type'
                    propertyName='atTypeAbsentId'
                    currentItem={findOneBy(atTypeChoicesForSubPeriods.absent, 'id', bellSchedule.atTypeAbsentId )}
                    items={atTypeChoicesForSubPeriods.absent}
                    nameAtr='displayName'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                { positiveAttendanceIsEnabled ?
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Early / On-Time - Minutes'
                    propertyName='bellPeriodPreStartPresentSeconds'
                    currentItem={findOneBy(presentMinuteList,'seconds', bellSchedule.bellPeriodPreStartPresentSeconds)}
                    items={presentMinuteList}
                    valAtr='seconds'
                    nameAtr='displayName'
                  />
                </Grid>
                  :
                  null
                }
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Start Tardy - Minutes'
                    propertyName='bellPeriodPostStartTardySeconds'
                    currentItem={findOneBy(minuteList,'seconds', bellSchedule.bellPeriodPostStartTardySeconds)}
                    items={minuteList}
                    valAtr='seconds'
                    nameAtr='displayName'
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Start Absent - Minutes'
                    propertyName='bellPeriodPostStartAbsentSeconds'
                    currentItem={findOneBy(minuteList,'seconds', bellSchedule.bellPeriodPostStartAbsentSeconds)}
                    items={minuteList}
                    valAtr='seconds'
                    nameAtr='displayName'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>

              </Grid>
            </Grid>
            <Grid item xs={3} >
              <BellRuleSVG style={{maxHeight:'250'}} {...bellSchedule} positiveAttendanceIsEnabled={positiveAttendanceIsEnabled}/>
            </Grid>
          </Grid>

          <Divider style={{marginTop:10}}/>

          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Tardy Excused Type'
                    propertyName='atTypeTardyExcusedId'
                    currentItem={findOneBy(atTypeChoicesForSubPeriods.tardyExcused, 'id', bellSchedule.atTypeTardyExcusedId )}
                    items={atTypeChoicesForSubPeriods.tardyExcused}
                    nameAtr='displayName'
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Absent Excused Type'
                    propertyName='atTypeAbsentExcusedId'
                    currentItem={findOneBy(atTypeChoicesForSubPeriods.absentExcused, 'id', bellSchedule.atTypeAbsentExcusedId )}
                    items={atTypeChoicesForSubPeriods.absentExcused}
                    nameAtr='displayName'
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={bellRulesEditUpdateProperty}
                    label='Out of Building'
                    propertyName='atTypeOutOfBuildingId'
                    currentItem={findOneBy(atTypeChoicesForSubPeriods.outOfBuilding, 'id', bellSchedule.atTypeOutOfBuildingId )}
                    items={atTypeChoicesForSubPeriods.outOfBuilding}
                    nameAtr='displayName'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={classes.gridItem}>
                  <FancySelect
                    dispatchUpdate={this.handleOutOfBuildingPropagationUpdateProperty}
                    label='Set Out for the Remaining Periods When Leaving the Building'
                    propertyName={IS_OUT_OF_BUILDING_PROPAGATION_ENABLED}
                    valAtr='val'
                    currentItem={findOneBy(yesNoBoolList, 'val', bellSchedule[IS_OUT_OF_BUILDING_PROPAGATION_ENABLED])}
                    items={yesNoBoolList}
                    nameAtr='displayName'
                  />
                </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={classes.gridItem}>
                <FancySelect
                  dispatchUpdate={bellRulesEditUpdateProperty}
                  label='Kiosk Reason'
                  propertyName='atReasonDefaultId'
                  currentItem={ findOneBy(atReasonChoicesForSubPeriods, 'id', bellSchedule.atReasonDefaultId ) }
                  items={ atReasonChoicesForSubPeriods }
                  nameAtr='displayName'
                />
              </Grid>
            </Grid>
          </Grid>
          { positiveAttendanceIsEnabled ?
            <Grid container>
              <Grid item xs={12}>
                <Divider style={{marginTop:10}}/>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography variant='h6' style={{margin:'20px', marginLeft:'0px'}}>
                  <ToggleIconButton
                    onTitle='Enabled'
                    offTitle='Disabled'
                    isOn={!!bellSchedule.isPaEnabled}
                    onClick={()=>{
                      bellRulesEditUpdateProperty({
                      propertyName:'isPaEnabled',
                      val:!bellSchedule.isPaEnabled
                      })
                    }}/>
                  Positive Attendance
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <FancySelect
                  dispatchUpdate={bellRulesEditUpdateProperty}
                  label='Attendance Monitor'
                  propertyName='paMode'
                  valAtr='val'
                  currentItem={ findOneBy(paModeList, 'val', bellSchedule.paMode ) }
                  items={ paModeList }
                  nameAtr='displayName'
                />
              </Grid>       
            </Grid>
            :
            null
          }
          { positiveAttendanceIsEnabled ?
            <Grid container>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography style={{margin:'20px', marginLeft:'0px', fontSize: '16px'}}>
                  <ToggleIconButton
                    onTitle='Enabled'
                    offTitle='Disabled'
                    isOn={!!bellSchedule.isNonAttendancePeriodsEnabled}
                    onClick={()=>{
                      bellRulesEditUpdateProperty({
                      propertyName:'isNonAttendancePeriodsEnabled',
                      val:!bellSchedule.isNonAttendancePeriodsEnabled
                      })
                    }}/>
                  Track attendance at open periods
                </Typography>
              </Grid>             
            </Grid>
            :
            null
          }
          <Grid >
            <Grid container style={{ alignItems: 'center'}}>
              <Button onClick={() => overridableAtTypesEdit() } color="primary">select types the kiosk entry can override</Button>
              <Typography style={{ marginLeft: '10px' }}>{`${facilityOverridableAtTypes.length} attendance types selected`}</Typography>
              <OverridableAttendanceTypesDialog/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{marginTop:10}}>
          <Button onClick={this.guessTypes} color="secondary">
            Guess Types
          </Button>
          <div style={{flexGrow:8}}/>
          <Button onClick={bellRulesEditDone} color="secondary">
            Cancel
          </Button>
          <Button onClick={bellRulesEditSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  const dialogState = state.admin.dialogs.bellRulesEditor ? state.admin.dialogs.bellRulesEditor : {};
  const atTypes = state.admin.atTypes ? state.admin.atTypes : [];
  const currentFacilityId =  state.admin.currentFacilityId;
  return { ...dialogState, atTypes, currentFacilityId };
}

const mapDispatchToProps = {
  bellRulesEditDone,
  bellRulesEditUpdateProperty,
  bellRulesEditSave,
  overridableAtTypesEdit
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(BellRulesDialog))