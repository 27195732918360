/* globals window */
/* eslint-disable no-fallthrough */
import moment from "moment-timezone"
import Cookies from 'js-cookie'
import {reduce} from '@relativity/js-util'
import {isA} from "redux/utils";
import {
  ADMIN_IS_READY,
  ADMIN_SET_CONFIGURATION,
  ADMIN_SET_CURRENT_PATH
} from 'redux/actions'


import {
  ADMIN_APP_SETTINGS_FETCH_RESPONSE,
  ADMIN_AT_RECORD_EDIT,
  ADMIN_AT_RECORD_EDIT_DONE,
  ADMIN_CHANGE_KIOSK_SETTING,
  ADMIN_CLOSE_KIOSK_EDITOR,
  ADMIN_EDIT_LOCATION_SETTINGS,
  ADMIN_SAVE_KIOSK_SETTINGS_RESPONSE,
  ADMIN_SAVE_LOCATION_SETTINGS_RESPONSE,
  ADMIN_SEARCH_STUDENTS_RESPONSE,
  ADMIN_SEARCH_LOCATOR_STUDENTS_RESPONSE,
  ADMIN_RESET_SEARCH_LOCATOR_STUDENTS_RESPONSE,
  ADMIN_SELECT_LOCATOR_STUDENT,
  ADMIN_SET_LOCATOR_STUDENT_INFO,
  ADMIN_SET_LOCATION_PAGE_INITAL_DATA,
  ADMIN_SET_LOCATION_PAGE_KIOSKS,
  ADMIN_SNACK_MESSAGE_HIDE,
  ADMIN_SNACK_MESSAGE_SHOW,
  ADMIN_STUDENTS_SEARCH_STATUS_MESSAGE,
  ADMIN_AT_RECORD_EDIT_UPDATE_PROPERTY,
  ADMIN_BELL_RULES_EDIT,
  ADMIN_BELL_RULES_EDIT_DONE,
  ADMIN_BELL_RULES_EDIT_UPDATE_PROPERTY,
  ADMIN_BELL_RULES_EDIT_SAVE_RESPONSE,
  ADMIN_MESSAGING_FETCH_RESPONSE,
  ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID_RESPONSE,
  ADMIN_MESSAGING_EDIT_UPDATE_CONTENT,
  ADMIN_SEARCH_STUDENTS,
  ADMIN_SEARCH_LOCATOR_STUDENTS,
  ADMIN_SET_LOCATOR_SCHEDULE_PICKER_DATE,
  ADMIN_SET_LOCATOR_SCHEDULE_PERIODS,
  ADMIN_SET_SYNC_QUEUES_RESPONSE,
  ADMIN_MESSAGING_EDIT_SAVE_CONTENT_RESPONSE,
  ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_RESPONSE,
  ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET,
  ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_CHANGEABLE,
  ADMIN_MESSAGING_EDIT_CHOOSE_CONTENT_MESSAGE,
  ADMIN_UPDATE_GENERAL_SETTING,
  ADMIN_APP_SETTINGS_SAVE_RESPONSE,
  ADMIN_LOGIN_SHOW_UI,
  ADMIN_USER_ACCOUNT_SEARCH,
  ADMIN_USER_ACCOUNT_SEARCH_RESPONSE,
  ADMIN_USER_ACCOUNT_EDIT,
  ADMIN_USER_ACCOUNT_EDIT_DONE,
  ADMIN_USER_ACCOUNT_EDIT_SAVE_RESPONSE,
  ADMIN_USER_ACCOUNT_EDIT_UPDATE_PROPERTY,
  ADMIN_MESSAGING_EDIT_UPDATE_CONTENT_STYLE,
  //ADMIN_HOME_PAGE_DATA_FETCH_RESPONSE,
  ADMIN_USER_ACCOUNT_OPEN_UPLOADER,
  ADMIN_USER_ACCOUNT_CLOSE_UPLOADER,
  ADMIN_USER_ACCOUNT_UPLOAD_UPDATE_PROPERTY,
  ADMIN_SEARCH_LOCATIONS_BY,
  ADMIN_APP_WORKER_JOB_FETCH_RESPONSE,
  ADMIN_WORKER_JOB_EDIT_DONE,
  ADMIN_WORKER_JOB_EDIT_SAVE_RESPONSE,
  ADMIN_WORKER_JOB_EDIT_UPDATE_PROPERTY,
  ADMIN_WORKER_JOB_EDIT,
  ADMIN_COURSES_FETCH_RESPONSE,
  ADMIN_AT_MONITOR_CHOOSER_COURSES_RESPONSE,
  ADMIN_AT_MONITOR_COURSES_SAVE_RESPONSE,
  ADMIN_AT_MONITOR_COURSES_EDIT_DONE,
  ADMIN_AT_MONITOR_COURSES_EDIT,
  ADMIN_AT_MONITOR_COURSES_CHOOSER_SET_IS_ENABLED,
  ADMIN_LOCATIONS_FETCH_RESPONSE,
  ADMIN_AT_MONITOR_LOCATIONS_EDIT,
  ADMIN_AT_MONITOR_LOCATIONS_EDIT_DONE,
  ADMIN_AT_MONITOR_CHOOSER_LOCATIONS_RESPONSE,
  ADMIN_AT_MONITOR_LOCATIONS_CHOOSER_SET_IS_ENABLED,
  ADMIN_AT_MONITOR_LOCATIONS_SAVE_RESPONSE,
  ADMIN_SCHOOL_YEARS_FETCH_RESPONSE,
  ADMIN_MIGRATE_SCHOOL_YEAR_RESPONSE,
  ADMIN_MIGRATE_SCHOOL_YEAR_ERROR,
  ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_PPK_EDIT,
  ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_WEB_EDIT,
  ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE,
  ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY,
  ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_APPLY,
  ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_EDITOR_PROPERTY,
  ADMIN_OVERRIDABLE_AT_TYPES_EDIT_DONE,
  ADMIN_OVERRIDABLE_AT_TYPES_RESPONSE,
  SINGLETON_KIOSK_EDIT_FETCH_RESPONSE,
  ADMIN_OVERRIDABLE_AT_TYPES_EDIT,
  ADMIN_OVERRIDABLE_AT_TYPES_CHOOSER_SET_IS_ENABLED,
  ADMIN_SEMESTER_RULE_EDIT,
  ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID_RESPONSE,
  ADMIN_SEMESTER_RULE_EDIT_DONE,
  ADMIN_SEMESTER_RULE_EDIT_SAVE_RESPONSE,
  ADMIN_ATTENDANCE_TERM_SET_IS_COUNT_RESET_ENABLED,
  KIOSK_INSTANCE_EDIT,
  SET_STUDENT_PAGE_SCHEDULE_ATTENDANCE,
  SET_STUDENT_DETAIL_PAGE_ATTENDANCE_VIEW,
  FETCH_HOME_PAGE_DATA_RESPONSE,
  REPORT_TYPES_FETCH_RESPONSE,
  REPORT_PAGE_EDIT_UPDATE_PROPERTY,
  REPORT_PAGE_FETCH_REPORT_BY_ID_RESPONSE,
  DANGEROUS_UPDATE_GENERAL_PROPERTY,
  DANGEROUS_UPDATE_CLEAR_GENERAL_GENERAL_PROPERTY_COOKIES,
  MESSAGING_EDIT_PREVIEW_CONTENT_RESPONSE,
  STUDENT_DETAIL_TOGGLE_SHOW_REPORTING_ONLY,
  SET_SCHEDULE_DISPLAY_SETTINGS,
  WORKER_JOB_VIEW_LOGS_DONE,
  WORKER_JOB_VIEW_LOGS,
  SET_STUDENT_PAGE_LIST_ATTENDANCE,
  ENTITY_CHOOSER_SET_IS_ENABLED,
  ENTITY_CHOOSER_OPEN_RESPONSE,
  ENTITY_SET_IS_ENABLED_RESPONSE,
  BUILDING_CHOOSER_OPEN_RESPONSE,
  BUILDING_CHOOSER_SET_IS_ENABLED,
  BUILDING_CHOOSER_DONE,
  BUILDING_CHOOSE_CONFIRM_RESPONSE,
  LOCATIONS_SYNC_WAIT_RESPONSE,
  WORKER_JOB_VIEW_LOGS_TOGGLE_EXPAND_DETAIL,
  DANGEROUS_PIRATE_ACTION,
  PIRATE_UPDATE_GENERAL_PROPERTY,
  PPK_CONFIGURATION_EDIT_RESPONSE,
  PPK_CONFIGURATION_EDIT_CLOSE,
  PPK_CONFIGURATION_EDIT_PROP_SET,

  STUDENT_DETAIL_PAGE_REPORT_EDIT_UPDATE_PROPERTY,
  STUDENT_DETAIL_PAGE_SET_INITIAL_DATA,
  STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG_RESPONSE,
  HIDE_ADD_ENTITIES_DIALOG,

  ADMIN_REMOVE_SKYWARD_STATE,
} from 'redux/admin/Actions'

import {findOneBy, putOneBy, newState, payloadOnly, indexify, indexifyArrays, findAllBy, sortList} from '@relativity/js-util'
import {
  AT_TYPE_ID_BELL_RULES,
  AT_TYPE_ID_NOT_SET,
  AT_TYPE_ID_PRESENT,
  AT_REASON_ID_NOT_SET,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS,
  ID_ZERO,
  KDCL_BUILDING,
  KDCL_FACILITY,
  KDCL_LOCATION,
  KDCL_ROOT,
  KDCL_INSTANCE,
  SECONDS_WEEK,
  SECURITY_ROLE_STAFF,
  AT_TYPE_ID_REPORT,
  AT_TYPE_ID_UNKNOWN,
  AT_TYPE_ID_SKYWARD_CUSTOM,
  SECURITY_ROLE_ADMIN,
  FORMAT_YMD,
  AT_TYPE_ID_TARDY,
  AT_TYPE_ID_ABSENT, AT_TYPE_ID_TARDY_EXCUSED, AT_TYPE_ID_ABSENT_EXCUSED, TYPE_INT, TYPE_YMD,
  AT_TYPE_ID_ON_SITE,
  rYMD, FORMAT_WEEKDAY, AT_TYPE_ID_OUT, AT_TYPE_ID_OUT_OF_BUILDING, CHECKIN_FLOW_STANDARD,
  KIOSK_TYPE_WEB,
  KIOSK_TYPE_PPK,
  GENERIC_AT_TYPES_IN_DATABASE_COUNT,
  SKYWARD_CUSTOM_AT_TYPE
} from "redux/constants"
import UserAccount from "common/model/UserAccount";
import {staffMainNavePages, staffPages} from "redux/BaseState";

window.moment = moment
window.ONE_MINUTE = 60
window.FIVE_MINUTES = 300
window.TEN_MINUTES = 600
window.ONE_HOUR = 3600

const sortBy = {
  displayName : sortList('displayName'),
  fullName : sortList('fullName'),
  displayOrderIndex : sortList('displayOrderIndex'),
}

const pathify = (item, parentPath='') => {
  if(!isA(item, 'object')) return item

  return Object.keys(item).reduce((acc, key) => {
    if(!isA(item[key], 'object')) {
      acc[parentPath + key] = item[key]
      return acc
    }
    return Object.assign(acc, pathify(item[key], parentPath + key + '.'))
  }, {})
}

window.pathify = pathify

//BEGIN PRE-ALPHA @relativity/js-util CODE

const searchAllExpressions = {
  '=':(compareValue, ignoreCase=false) => {
    if(ignoreCase) return (val) => val.toLowerCase() === compareValue
    return (val) => val === compareValue
  },
  '<':(compareValue, ignoreCase=false) => {
    if(ignoreCase) return (val) => val.toLowerCase() < compareValue
    return (val) => val < compareValue
  },
  '>':(compareValue, ignoreCase=false) => {
    if(ignoreCase) return (val) => val.toLowerCase() > compareValue
    return (val) => val > compareValue
  },
  'startsWith': (compareValue, ignoreCase=false) => {
    if(ignoreCase) return (val) => val.toLowerCase().startsWith(compareValue)
    return (val) => val.startsWith(compareValue)
  },
  'endsWith':(compareValue, ignoreCase=false) => {
    if(ignoreCase) return (val) => val.toLowerCase().endsWith(compareValue)
    return (val) => val.endsWith(compareValue)
  },
  'includes': (compareValue, ignoreCase=false) => {
    if(ignoreCase) return (val) => val.toLowerCase().includes(compareValue)
    return (val) => val.includes(compareValue)
  },
  'like':(compareValue, ignoreCase=false) => {
    let compVal
    if(compareValue.startsWith('%') && compareValue.endsWith('%')) {
      compVal = compareValue.slice(1,-1)
      return searchAllExpressions.includes(compVal, ignoreCase)
    }
    if(compareValue.startsWith('%')) {
      compVal = compareValue.slice(1)
      return searchAllExpressions.endsWith(compVal, ignoreCase)
    }
    if(compareValue.endsWith('%')) {
      compVal = compareValue.slice(0,-1)
      return searchAllExpressions.startsWith(compVal, ignoreCase)
    }
    return searchAllExpressions['='](compareValue, ignoreCase)
  },
}

/**
 * Creates a re-usable search function
 * @param searchParams - {}
 *  each property of search params should be named after a key to be compared.
 * @returns function
 */
export const searchAllBy = (searchParams) => {
  const searchExpressions = {}
  const searchKeys = Object.keys(searchParams)

  searchKeys.forEach((key) => {
    switch (typeof searchParams[key]) {
      case 'function' :
        searchExpressions[key] = (searchParams[key])
        break
      case 'object' :
        if(searchParams[key].constructor === Array) {
          if(searchParams[key].length < 1) throw Error(`searchAllBy requires search array expressions be in the form of ['comparator', value, [ignoreCase]]` )
          if(!searchAllExpressions.hasOwnProperty(searchParams[key][0])) throw Error(`searchAllBy cannot search by '${searchParams[key][0]}`)
          searchExpressions[key] = searchAllExpressions[searchParams[key][0]](searchParams[key][1], (searchParams[key][2] || false))
        }
        break
      default :
        searchExpressions[key] = searchAllExpressions['='](searchParams[key])
    }
  })

  return (list, originals=false) => {
    return list.reduce((acc, item)=> {
      let push = true
      for (let i = 0; i < searchKeys.length; i++) {
        let key = searchKeys[i]
        if (!searchExpressions[key](item[key])) {
          push = false
          break
        }
      }
      if(push) {
        if(originals) acc.push(item)
        else acc.push(Object.assign({}, item))
      }
      return acc
    }, [])
  }
}

//END PRE-ALPHA @relativity/js-util CODE


/**
 * Creates a presentationally ordered list of valid atTypes that can be displayed, and assigned to a record
 * @param zeroAtTypes
 * @param currentAtTypeId
 * @returns {*[]|*}
 */
const makeAtTypeForAtRecordList = (zeroAtTypes, currentAtTypeId, positiveAttendanceIsEnabled) => {
  let notSet = false, present = false
  if(currentAtTypeId === AT_TYPE_ID_REPORT) return [findOneBy(zeroAtTypes, 'id', AT_TYPE_ID_REPORT)]
  let atTypes = zeroAtTypes.reduce((acc, atType) => {
    switch(atType.id) {
      case AT_TYPE_ID_NOT_SET :
        notSet = atType
        break
      case AT_TYPE_ID_PRESENT :
        present = atType
        break
      case AT_TYPE_ID_BELL_RULES :
      case AT_TYPE_ID_REPORT :
        break
      case AT_TYPE_ID_ON_SITE :
          break
      case AT_TYPE_ID_UNKNOWN :
        if(currentAtTypeId !== AT_TYPE_ID_UNKNOWN) {
          break
        }
        //fallthrough ok
      default :
        acc.push(atType)
    }
    return acc
  }, [])
  atTypes = sortBy.displayName(atTypes)
  if(positiveAttendanceIsEnabled || currentAtTypeId === present.id) { //we are adding conditionally here, instead of filtering out completely in the api, because licensing could change from PA to TK during an install, and we don't want the UI to crash or become 'weird' in that situation
    atTypes.unshift(present)
  }

  if(!currentAtTypeId) atTypes.unshift(notSet)


  return atTypes
}

const appSettingsFetchResponse = (state, settings) => {
  return newState(state, 'settings', settings, false)
}

const appWorkerJobFetchResponse = (state, workerJobs) => {
  return newState(state, 'workerJobs', sortBy.displayOrderIndex(workerJobs), false)
}

const searchLocationsBy = (state, inSearchParams) => {
  const searchConfig = {}
  const searchParams = (inSearchParams)? inSearchParams : state.locationPage.search.params
  if(searchParams.buildingId && parseInt(searchParams.buildingId) > 0) {
    searchConfig.buildingId = parseInt(searchParams.buildingId)
  } else {
    searchConfig.buildingId = ['>', 10]
  }
  searchConfig.id = ['>', 0]
  if(searchParams.locationName) {
    searchConfig.name = ['includes', searchParams.locationName, true]
  }
  const matchingLocations = searchAllBy(searchConfig)(state.locations).sort((a, b)=> (a.name < b.name? -1 : (a.name > b.name? 1 : 0)))

  return newState(state, 'locationPage', {
    search: {
      params:Object.assign({locationName:'',buildingId:-1}, searchParams),
      matchingLocations }
  })
}


const setCurrentPath = (state, path) => {
  console.log('setCurrentPath', path)
  let {currentPage, currentFacilityId} = state
  let currentItemId = ''

  if(path === state.currentPath) {
    return state
  }


  for(let i = 0; i < state.pages.length; i++) {
    let page = state.pages[i]
    let match = page.reTos.reduce( (match, reTo) => {
      if(match) return match
      return reTo.exec(path)
    }, null)
    if(match) {
      currentPage = page
      const facilityId = parseInt(match[1])
      currentFacilityId = isNaN(facilityId)? '' : facilityId
      if(match[2]) {
        switch (page.itemType) {
          case TYPE_YMD :
            currentItemId = rYMD.exec(match[2])? match[2] : ''
            break
          case TYPE_INT :
          default :
            const itemId = parseInt(match[2])
            currentItemId = isNaN(itemId)? '' : itemId
        }
      }
      break
    }
  }
  let transitionState = setCurrentPathIds(state, currentFacilityId, currentItemId)
  return newState(transitionState, {currentPath: path, currentPage})
}


const setCurrentPathIds = (state, id, itemId) => {
  if(id === state.currentFacilityId && state.itemId === itemId) {
    return state
  }
  let transitionState = newState(state,{currentFacilityId: id, currentItemId:itemId})
  return transitionState
}

const ppkConfigurationEditDone = (state) => {
  return newState(state, 'dialogs.ppkEditor', false)
}

const ppkConfigurationEditPropSet = (state, payload) => {
  const {ppkEditor} = state.dialogs
  if(!ppkEditor.kiosk || ppkEditor.kiosk.id !== payload.id) {
    console.error(`Unable to set value for PPK kiosk as UI is probably in an incorrect state. Please reload the page, or share with tech support id:${payload.id}, name:${payload.propName}`)
    return state;
  }
  console.log(payload)

  return newState(state, `dialogs.ppkEditor.kiosk.${payload.propName}`, payload.val)
}

const ppkConfigurationEditResponse = (state, payload) => {
  const {kiosk, location} = payload
  const atTypes = state.positiveAttendanceIsEnabled ? state.kioskModes : state.kioskModes.reduce((acc, atType) => {
      if(atType.id !== AT_TYPE_ID_PRESENT) acc.push(atType)
      return acc
    }, [])
  const editorTitle = 'PPK Kiosk - ' + (kiosk.id > 0? `${kiosk.id} ${location.displayName}` : 'New')
  const editorDescription = kiosk.id > 0? `Changes to this kiosk will take effect when you hit save.` : `Once you hit save, the PPK Group & Device ID combination is locked to this location.`
  const ppkEditor = {
    atTypes,
    level: KDCL_INSTANCE,
    kioskDefaultConfiguration: location.kioskDefaultConfiguration,
    kiosk: {...kiosk},
    location,
    editorTitle,
    editorDescription
  }

  return newState(state, 'dialogs.ppkEditor', ppkEditor, false)
}



const singletonKioskEditFetchResponse = (state, singletonKiosk) => {
  const kiosk = singletonKiosk //findOneBy(state.locationDetailPage.kiosks, 'id', kioskId)

  const atTypes = state.positiveAttendanceIsEnabled? state.kioskModes : state.kioskModes.reduce((acc, atType) => {
      if(atType.id !== AT_TYPE_ID_PRESENT) acc.push(atType)
      return acc
    }, [])

  const kioskConfigurationEditor = {
    atTypes,
    level: KDCL_INSTANCE,
    kioskDefaultConfiguration: {
      "id": 7,
      "facilityId": 0,
      "buildingId": 0,
      "locationId": 0,
      "isDefault": false,
      "isEnabled": true,
      "canPrint": true,
      "enableEmailSlip": false,
      "canPrintChangeable": false,
      "checkinFlow": CHECKIN_FLOW_STANDARD,
      "checkinFlowChangeable": false,
      "enableKeyPad": true,
      "enableKeyPadChangeable": false,
      "enableCamera": true,
      "enableCameraChangeable": false,
      "enableGps": false,
      "enableGpsChangeable": false,
      "attendanceDirection": "multi",
      "level": KDCL_LOCATION,
      "attendanceDirectionChangeable": false,
      "atTypeId": 1,
      "atTypeIdChangeable": false,
      "isOutOfBuilding": false,
      "isOutOfBuildingChangeable": false,
      "acceptKeyboardInput": true,
      "acceptKeyboardInputChangeable": false,
      "requestSendReport": true,
      "requestSendReportChangeable": false,
      "jsbCameraConfiguration": {
        "halfSample": true,
        "numWorkers": 4,
        "barcodeType": "code_39_reader",
        "cameraDeviceName": "",
        "locatorPatchSize": "medium",
        "inputStreamResolution": "640x480"
      },
      "maxLeaseLength": -1,
    },
    kiosk: { ...kiosk},
    editorTitle: `Virtual Kiosk`,
    editorDescription: `Changes to the Virtual Kiosk will take effect when you hit save.`
  }

  return newState(state, 'dialogs.kioskConfigurationEditor', kioskConfigurationEditor, false)
}

const kioskInstanceEdit = (state, kioskId) => {
  const kiosk = findOneBy(state.locationDetailPage.kiosks, 'id', kioskId)
  if(!kiosk) {
    throw Error(`Kiosk ${kioskId} not found`)
  }
  const location = state.locationDetailPage.location
  const atTypes = state.positiveAttendanceIsEnabled? state.kioskModes : state.kioskModes.reduce((acc, atType) => {
      if(atType.id !== AT_TYPE_ID_PRESENT) acc.push(atType)
      return acc
    }, [])

  const kioskConfigurationEditor = {
    atTypes,
    level: KDCL_INSTANCE,
    kioskDefaultConfiguration: location.kioskDefaultConfiguration,
    kiosk: Object.assign({}, kiosk),
    location,
    editorTitle: `Kiosk - ${kiosk.id} ${location.displayName}`,
    editorDescription: `Changes to this kiosk will take effect when you hit save.`
  }

  return newState(state, 'dialogs.kioskConfigurationEditor', kioskConfigurationEditor, false)
}


const editKioskDefaultConfiguration = (state, payload) => {
  const {kioskDefaultConfiguration} = payload
  const {level} = kioskDefaultConfiguration//(kioskDefaultConfiguration.facilityId === ID_ZERO)? KDCL_ROOT : (kioskDefaultConfiguration.locationId === ID_ZERO? KDCL_FACILITY : KDCL_LOCATION)
  const location = (level === KDCL_LOCATION)? state.locationDetailPage.location : false
  const facility = state.byId.facility(kioskDefaultConfiguration.facilityId)
  const building = state.byId.building(kioskDefaultConfiguration.buildingId)
  const atTypes = state.positiveAttendanceIsEnabled? state.kioskModes : state.kioskModes.reduce((acc, atType) => {
    if(atType.id !== AT_TYPE_ID_PRESENT) acc.push(atType)
    return acc
  }, [])
  const atReasons = facility.atReasons;
  let editorTitle = ''
  let editorDescription = ''

  switch(level) {
    case KDCL_BUILDING :
      editorTitle = `Building - ${building.displayName}`
      editorDescription = `New Locations at ${building.displayName} will inherit these settings`
      break
    case KDCL_LOCATION :
      editorTitle = `Location - ${location.displayName}`
      editorDescription = `New Kiosks at ${location.displayName} will inherit these settings`
      break
    case KDCL_FACILITY :
      editorTitle = `Entity - ${facility.displayName}`
      editorDescription = `New Locations at ${facility.displayName} will inherit these settings`
      break
    case KDCL_ROOT :
      editorTitle = 'General Defaults'
      editorDescription = 'New Facilities will inherit these settings'
      break
    default :
      throw Error('Invalid level for editKioskDefaultConfiguration admin reducer')
  }

  const kioskConfigurationEditor = {
    atTypes,
    atReasons,
    level,
    kioskDefaultConfiguration,
    kiosk: false,
    location,
    facility,
    editorTitle,
    editorDescription
  }
  return newState(state, 'dialogs.kioskConfigurationEditor', kioskConfigurationEditor, false)
}

const editCurrentLocationDefaultConfiguration = (state) => {
  const atTypes = findOneBy(state.facilities, 'id', state.locationDetailPage.location.facilityId).atTypes.slice()
  sortBy.displayName(atTypes)
  atTypes.unshift(findOneBy(state.zeroFacility.atTypes, 'id', AT_TYPE_ID_BELL_RULES))

  const kioskConfigurationEditor = {
    atTypes,
    level:KDCL_LOCATION,
    kioskDefaultConfiguration: state.locationDetailPage.location.kioskDefaultConfiguration,
    kiosk: false,
    location: state.locationDetailPage.location
  }
  return newState(state, 'dialogs.kioskConfigurationEditor', kioskConfigurationEditor, false)
}

const zeroReducer = (acc, item) => {
  if(item.id !== 0) {
    acc.push(item)
  }
  return acc
}

const setConfiguration = (state, data) => {
  let bootState = data.facilities.reduce((acc, facility) => {
    if(facility.atTypes) {
      acc.atTypes = acc.atTypes.concat(facility.atTypes)
      acc.atReasons = acc.atReasons.concat(facility.atReasons)
    }
    return acc
  }, {atTypes:[], atReasons:[]/*, bellSchedulePeriods: [] */})

  bootState.userAccount = data.user
  bootState.loggedInUser = data.user
  bootState.schoolYear = data.schoolYear
  bootState.positiveAttendanceIsEnabled = data.positiveAttendanceIsEnabled
  bootState.ppkIsEnabled = data.ppkIsEnabled
  bootState.piratesAndDragonsEnabled = data.piratesAndDragonsEnabled
  bootState.ids = data.ids
  bootState.skywardInfo = data.skywardInfo

  const locations = sortList('displayName')(data.locations)
  bootState.byId = {
    location: indexify(locations, 'id'),
    facility: indexify(data.facilities, 'id'),
    building: indexify(data.buildings, 'id'),
    atType: indexify(bootState.atTypes.concat(SKYWARD_CUSTOM_AT_TYPE), 'id'),
    atReason: indexify(bootState.atReasons, 'id'),
    locationsByBuilding: indexifyArrays(locations, 'buildingId')
  }


  bootState.facilities = sortBy.displayName(data.facilities.reduce(zeroReducer, []))
  bootState.allFacilities = sortBy.displayName(data.allFacilities.reduce(zeroReducer, []))
  bootState.buildings = sortBy.displayName(data.buildings.reduce(zeroReducer, []))
  bootState.allBuildings = [Object.assign({}, bootState.byId.building(ID_ZERO), {displayName:'All Buildings'})].concat(bootState.buildings) //<-- sorry about that
  bootState.permittedBuildings = getPermittedBuildings(bootState, bootState.buildings)
  bootState.locations = locations

  bootState.zeroFacility = bootState.byId.facility(ID_ZERO)

  bootState.kioskModes = sortBy.displayName(
    bootState.zeroFacility.atTypes.reduce(
      (acc, atType) => { if(atType.isKioskMode) acc.push(atType); return acc}
      ,
      []
    )
  )

  if(bootState.loggedInUser.securityRoles.includes(SECURITY_ROLE_STAFF)) {
    bootState.pages = staffPages
    bootState.mainNavPages = staffMainNavePages
  }

  bootState.appVersion = data.appVersion

  bootState = newState(state, bootState)

  const dangerouslyUpdatedGeneralProperties = Cookies.get()
  console.log("bootState", bootState)
  bootState = reduce(
    dangerouslyUpdatedGeneralProperties,
    (acc, val, key) => {
      if(key.startsWith('admin.')) {
        if(val === "false") {
          return newState(acc, key.replace('admin.',''), false)
        } else if(val === "true") {
          return newState(acc, key.replace('admin.',''), true)
        } else {
          return newState(acc, key.replace('admin.',''), val)
        }
      }
      if(key.startsWith('pirate.') && bootState.piratesAndDragonsEnabled) {
        if(val === "false") {
          return newState(acc, key.replace('pirate.','pirate.'), false)
        } else if(val === "true") {
          return newState(acc, key.replace('pirate.','pirate.'), true)
        } else {
          try {
            return newState(acc, key.replace('pirate.','pirate.'), JSON.parse(val)) //cookies isn't automatically parsing
          } catch (e) {
            return newState(acc, key.replace('pirate.','pirate.'), val)
          }
        }
      }
      return acc
    },
    bootState
  )
  return bootState
}

const getPermittedBuildings = (state, buildings) => {
  return (state.loggedInUser.securityRoles.includes(SECURITY_ROLE_ADMIN)) ||
    (state.loggedInUser.facilities && state.loggedInUser.facilities.includes(ID_ZERO))
      ? buildings
      : buildings.reduce((acc, building) => {
          if(state.loggedInUser.securityAccess.buildingIds.includes(building.id)) {
            acc.push(building)
          }
          return acc
        }, [])
}

const setLocationPageData = (state, action) => {
  const location = action
  return newState(state, 'locationDetailPage', {location, kiosks:location.kiosks})
}

const setStudentPageListAttendance = (state, {atRecords, pageNum, pageSize, totalCount}) => {
  const atRecordsByDateObj = atRecords.reduce((acc, record) => {
    const mo = moment.unix(record.uTime)
    record.tod = mo.format('HH:mm')
    if(!acc[record.ymd]) acc[record.ymd] = {ymd:record.ymd, mdy:record.mdy, weekday:mo.format('dddd'),  atRecords:[]}
    acc[record.ymd].atRecords.push(record)
    return acc
  }, {})
  const atRecordsByDate = Object.keys(atRecordsByDateObj).sort().reverse().map(key=>atRecordsByDateObj[key])
  return newState(state, 'studentDetailPage.listView', {atRecordsByDate, pageNum, pageSize, totalCount})
}

const studentDetailPageSetInitialData = (state, payload) => {
  return newState(state, 'studentDetailPage', payload)
}

const studentDetailPageReportEditUpdateProperty = (state, payload) => {
  const attendanceReport = {report:false}
  switch(payload.propertyName) {
    case 'reportTypeId' :
      attendanceReport.reportTypeId = payload.val
      if(payload.val === ID_ZERO) {
        attendanceReport.jsbCustomConfiguration = false
      } else {
        attendanceReport.jsbCustomConfiguration = JSON.parse(JSON.stringify(state.reportPage.reportTypesById(payload.val).jsbCustomConfiguration))
      }
      break
    case 'lastDateYMD' :
    case 'firstDateYMD' :
      attendanceReport[payload.propertyName] = payload.val
    //no default
  }
  if(payload.propertyName.startsWith('jsbCustomConfiguration')) {
    return newState(state, `studentDetailPage.attendanceReport.${payload.propertyName}`, payload.val)
  }
  return newState(state, 'studentDetailPage.attendanceReport', attendanceReport)
}

const studentDetailPageReportFetchAttendanceLogResponse = (state, payload) => {
  return newState(state, 'studentDetailPage.attendanceReport.report', payload)
}

const compileWeekCalendarItemsByHour = (weekCalendar, itemType, uTimePropName, items) => {
  let itemTypes = itemType + 's'
  const itemTypeById = itemType + 'ById'

  for(let i = 0; i < items.length; i++) {
    let item = items[i]
    let mnt = moment.unix(item[uTimePropName])
    let hour = mnt.get('hour')
    let ymd = mnt.format('YYYY-MM-DD')

    if(!weekCalendar.byDate.hasOwnProperty(ymd)) {
      weekCalendar.byDate[ymd] = {
        atRecords:[],
        schedulePeriods:[],
        displayPeriods:[]
      }
    }

    if(!weekCalendar.byHour.hasOwnProperty(hour)) {
      if(weekCalendar.maxHour < hour) weekCalendar.maxHour = hour
      if(weekCalendar.minHour > hour) weekCalendar.minHour = hour
      weekCalendar.byHour[hour] = {
        displayName: mnt.format('h:00a'),
        dates:{}
      }
    }
    if(!weekCalendar.byHour[hour].dates.hasOwnProperty(ymd)) {
      weekCalendar.byHour[hour].dates[ymd] = {
        atRecords:[],
        reportingAtRecords:[],
        schedulePeriods:[],
        displayPeriods:[]
      }
    }

    item.tod = mnt.format("h:mma")
    item.displayFullDate = mnt.format('YYYY-MM-DD hh:mm')
    item.minuteOffset = mnt.get('minutes')
    if(itemType === 'atRecord') {
      if(item.isReportingAtRecord) {
        weekCalendar.byHour[hour].dates[ymd].reportingAtRecords.push(item)
      }
    } else {
      if(itemType === 'displayPeriod'){
        const attendancePeriodCode = item.AttendancePeriod.attendancePeriodCode;
        const schedulePeriods = weekCalendar.byHour[hour].dates[ymd]['schedulePeriods'];
        const hasSameCode = schedulePeriods.some(sp => sp.periodNumber === attendancePeriodCode);

        if (hasSameCode) {
          continue;
        }

        itemTypes = 'schedulePeriods';
        let displayName = item.isLunchPeriod ? 'Lunch' : '';
        displayName = item.isOutsideRegularSchoolday ? 'Outside regular schoolday' : displayName;

        item = {
          ...item,
          isEmptyPeriod: true,
          periodNumber: item.AttendancePeriod.attendancePeriodCode,
          uStart: item.uStart,
          uEnd: item.uEnd,
          displayName: displayName,
          courseDescription: displayName,
          facility: item.Facility.name,
          facilityId: item.Facility.id
        }
      }
      let uTime = (parseInt(item.uEnd) - parseInt(item.uStart))
      item.minuteLength = Math.floor( uTime / 60)
    }
    weekCalendar.byDate[ymd][itemTypes].push(item)
    weekCalendar.byHour[hour].dates[ymd][itemTypes].push(item)
    weekCalendar[itemTypeById][item.id] = item
  }
  return weekCalendar
}

const setStudentPageScheduleAttendance = (state, schedAttendance) => {
  let weekCalendar = {
    byHour:{}, byDate:{}, maxHour:0, minHour:99,
    atRecordById:{}, schedulePeriodById:{}, displayPeriodById:{},
    scheduleDates:schedAttendance.scheduleDates,
    ymdEnd:schedAttendance.ymdEnd, ymdStart:schedAttendance.ymdStart,
    reportingAtRecordsByAuspId: {},
    atRecordsForNonAttendancePeriods: []
  }

  weekCalendar = compileWeekCalendarItemsByHour(weekCalendar, 'schedulePeriod', 'uStart', schedAttendance.schedulePeriods)
  weekCalendar = compileWeekCalendarItemsByHour(weekCalendar, 'atRecord', 'uTime', schedAttendance.atRecords)

  let currentFacilityId = state.currentFacilityId;
  let currentFacility = state.facilities.find(f => f.id === currentFacilityId);
  let currentBellSchedule = currentFacility.bellSchedule.find(b => b.isCurrent);
  const isNonAttendancePeriodsEnabled = currentBellSchedule.isNonAttendancePeriodsEnabled;

  if(isNonAttendancePeriodsEnabled){
    weekCalendar = compileWeekCalendarItemsByHour(weekCalendar, 'displayPeriod', 'uStart', schedAttendance.displayPeriods)
  }

  schedAttendance.atRecords.forEach(atRecord => {
    if(atRecord.isReportingAtRecord && !atRecord.isDeleted) { //deleted check for thoroughness (current api does not return deleted records, but that might not always be the case)
      weekCalendar.reportingAtRecordsByAuspId[atRecord.atUserSchedulePeriodId] = atRecord
    }
    else if(!atRecord.isReportingAtRecord && atRecord.atTypeId === AT_TYPE_ID_ON_SITE){
      weekCalendar.atRecordsForNonAttendancePeriods.push(atRecord)
    }
  })

  for(let ymd in weekCalendar.byDate) if(weekCalendar.byDate.hasOwnProperty(ymd)) {
    let {schedulePeriods} = weekCalendar.byDate[ymd]
    weekCalendar.byDate[ymd].schedulePeriodsByOverlapId = {}
    schedulePeriods.sort((a,b) => a.uStart < b.uStart? -1 : a.uStart > b.uStart? 1 : 0)
    for(let i = 0; i < schedulePeriods.length; i++) {
      schedulePeriods[i].overLapId = i
      schedulePeriods[i].overlaps = []
      schedulePeriods[i].overlapsBefore = []
      schedulePeriods[i].overlapsAfter = []
      schedulePeriods[i].leftPercent = 0
      schedulePeriods[i].widthPercent = 100

    }
    for(let i = 0; i < schedulePeriods.length; i++) {
      let earlierPeriod = schedulePeriods[i]
      for(let j = i+1; j < schedulePeriods.length; j++) {
        let laterPeriod = schedulePeriods[j]
        if(earlierPeriod.uEnd > laterPeriod.uStart) {
          earlierPeriod.overlaps.push(j)
          earlierPeriod.overlapsAfter.push(j)

          laterPeriod.overlaps.push(i)
          laterPeriod.overlapsBefore.push(i)
        }
      }
      earlierPeriod.widthPercent = Math.floor(100 / (earlierPeriod.overlaps.length + 1))
      if(earlierPeriod.overlapsBefore.length > 0) {
        let mostRecentPredecessorPeriod = schedulePeriods[earlierPeriod.overlapsBefore[earlierPeriod.overlapsBefore.length - 1]]
        if(mostRecentPredecessorPeriod.leftPercent < earlierPeriod.widthPercent) {
          earlierPeriod.leftPercent = mostRecentPredecessorPeriod.leftPercent + mostRecentPredecessorPeriod.widthPercent
        }
      }
    }
  }

  weekCalendar.currentYMD = schedAttendance.currentYMD
  weekCalendar.schedulePeriodByIdFn = indexify([{id:0, uStart:0, todStartSeconds:0, periodNumber: 'No Period'}].concat(schedAttendance.schedulePeriods), 'id')
  return newState(state, 'studentDetailPage.weekCalendar', weekCalendar)
}

const setStudentDetailPageAttendanceView = (state, {attendanceView}) => {
return newState(state, 'studentDetailPage.attendanceView', attendanceView)
}

const studentDetailPageToggleShowReportingOnly = (state) => {
  try {
    return newState(state, 'studentDetailPage.showReportingOnly', !state.studentDetailPage.showReportingOnly)
  } catch(e) {
    console.error(e)
    return state
  }
}

const setSchedDispSettings = (state, payload) => {
  return newState(state, 'studentDetailPage.schedDispSettings', payload)
}

const entitySetIsEnabledResponse = (state, inputFacilities) => {
  const { allFacilities } = state;
  const updatedAllFacilities = allFacilities.map(facility => {
    const matchingFacility = inputFacilities.find(input => input.id === facility.id);
    if (matchingFacility) {
      return {
        ...facility,
        isEnabled: matchingFacility.isEnabled
      };
    }
    return facility;
  });

  const updatedFacilities = inputFacilities.filter(facility => facility.isEnabled);

  return {
    ...state,
    facilities: updatedFacilities,
    allFacilities: updatedAllFacilities
  };
};

const hideAddEntitiesDialog = (state) => {
  return newState(state, {entityChooser:{entities:false}})
}

const setLocationPageKiosks = (state, action) => {
  return newState(state, 'locationDetailPage.kiosks', action.payload)
}


const snackMessageHide = (state) => {
  return newState(state, 'snackMessage', {show:false, autoHideMillis:DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS}, false)
}


const snackMessageShow =(state, payload) => {
  const snackMessage = Object.assign({show:true, autoHideMillis:DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS}, payload)
  return newState(state, 'snackMessage', snackMessage, false)
}


const changeKioskSetting = (state, payload) => {
  const kioskConfigurationEditor = state.dialogs.kioskConfigurationEditor
  if(!kioskConfigurationEditor.kiosk || kioskConfigurationEditor.kiosk.id !== payload.id) {
    console.error(`Unable to set value for kiosk as UI is probably in an incorrect state. Please reload the page, or share with tech support id:${payload.id}, name:${payload.propName}`)
    return state;
  }
  return newState(state, `dialogs.kioskConfigurationEditor.kiosk.${payload.propName}`, payload.value)
}

const kioskDefaultConfigurationPropSet = (state, payload) => {
  const kioskConfigurationEditor = state.dialogs.kioskConfigurationEditor
  if(kioskConfigurationEditor.kioskDefaultConfiguration.id !== payload.id) {
    console.error(`Unable to set default setting for location as UI is probably in an incorrect state. Please reload the page, or share with tech support id:${payload.id}, locationId:${payload.locationId}`)
    return state;
  }
  const kioskDefaultConfiguration = {
    isDirty: true,
    [payload.propName]:payload.value
  }
  return newState(state, `dialogs.kioskConfigurationEditor.kioskDefaultConfiguration`, kioskDefaultConfiguration)
}

const kioskDefaultConfigurationPropSetChangeable = (state, payload) => {
  const kioskConfigurationEditor = state.dialogs.kioskConfigurationEditor
  if(kioskConfigurationEditor.kioskDefaultConfiguration.id !== payload.id) {
    console.error(`Unable to set changeable for location as UI is probably in an incorrect state. Please reload the page, or share with tech support id:${payload.id}, locationId:${payload.locationId}`)
    return state;
  }
  return newState(state, `dialogs.kioskConfigurationEditor.kioskDefaultConfiguration.${payload.propName}Changeable`, payload.isChangeable)
}

const closeKioskEditor = (state) => {
  return newState(state, 'dialogs.kioskConfigurationEditor',false)
}

const saveKioskSettingsResponse = (state, data) => {
  const kiosks = putOneBy(state.locationDetailPage.kiosks, 'id', data)

  return newState(state, 'locationDetailPage.kiosks', kiosks, false)
}

const saveLocationSettingsResponse = (state, data) => {
  if(state.locationDetailPage.location.id === data.locationId)
    return newState(state, 'locationDetailPage.location.kioskDefaultConfiguration', data, false)

  console.warn(`saveLocationSettingsResponse was called with a kioskDefaultConfiguration object (locationId:${data.locationId} that does not belong to the current locationDetailPage.location: ${state.locationDetailPage.location.id}`, state, data)
  return state
}

const updateStudentSearchStatus = (state, payload) => {
  return newState(state, 'studentSearchPage.statusMessage', payload)
}

const updateSearchStudentParams = (state, payload) => {
  return newState(state, 'studentSearchPage', payload)
}

const updateSearchLocatorStudentParams = (state, payload) => {
  return newState(state, 'locatorStudentPicker', payload)
}

const selectLocatorStudent = (state, payload) => {
  return newState(state, 'locatorStudentPicker', payload)
}

const setLocatorSchedulePickerDate = (state, date) => {
  return newState(state, 'locatorSchedule', {pickerDate: date})
}

const setLocatorSchedulePeriods = (state, periods) => { 
  return newState(state, 'locatorSchedule', {periods})
}

const setLocatorStudentInfo = (state, payload) => {
  return newState(state, 'locatorStudentInfo', payload)
}

const searchStudentsResponse = (state, payload) => {
  const statusMessage = (payload.pagination.rowCount > 0)? `Showing ${payload.results.length} of ${payload.pagination.rowCount} students.` : 'No students found.'
  return newState(state, 'studentSearchPage', {statusMessage, matchingStudents: payload.results})
}

const searchLocatorStudentsResponse = (state, payload) => {  
  const statusMessage = (payload.pagination.rowCount > 0)? `Showing ${payload.results.length} of ${payload.pagination.rowCount} students.` : 'No students found.'
  return newState(state, 'locatorStudentPicker', {statusMessage, matchingStudents: payload.results})
}

const resetSearchLocatorStudentsResponse = (state) => {  
  const statusMessage = null
  return newState(state, 'locatorStudentPicker', {statusMessage, matchingStudents: []})
}

const setSyncQueuesResponse = (state, payload) => {
  return newState(state, 'queueSyncResponse', payload)
}

/**
 * This function expects payload.atRecord.facilityId to already be set to a valid > 0 integer
 * @param state
 * @param payload
 * @returns {any}
 */
const atRecordEdit = (state, payload) => {
  const {atRecord, student, weekCalendar} = payload
  const locations = findAllBy(state.locations, 'facilityId', atRecord.facilityId)
  const location = findOneBy(state.locations, 'id', atRecord.locationId)
  const facility = findOneBy(state.facilities, 'id', atRecord.facilityId)

  /*
  if(atRecord.schedulePeriodId) {
    //(weekCalendar.schedulePeriodByIdFn(atRecord.schedulePeriodId).facilityId)
  } */

  let atTypes = makeAtTypeForAtRecordList(state.zeroFacility.atTypes, atRecord.atTypeId, state.positiveAttendanceIsEnabled)
  let currentType = state.atTypes.find(atType => atType.id === atRecord.atTypeId);
  if (!atTypes.some(atType => atType.id === currentType.id)) {
    atTypes = [...atTypes, currentType];
  }
  let atReasons = state.zeroFacility.atReasons.concat(sortBy.displayName(facility.atReasons)) //makeAtTypeForAtRecordList(state.zeroFacility.atReasons, atRecord.atReasonId)

  if(!atRecord.locationId) {
    locations.unshift(location)
  }

  const atRecordEditor = {
    student,
    atRecord,
    locations,
    location,
    weekCalendar,
    atTypes,
    atReasons
  }

  return newState(state, 'dialogs.atRecordEditor', atRecordEditor)
}


const atRecordEditDone = (state) => {
  return newState(state, 'dialogs.atRecordEditor', false)
}

const removeNotSetAtType = atTypes => atTypes.reduce((acc, atType) => {
  if(atType.id !== AT_TYPE_ID_NOT_SET) {
    acc.push(atType)
  }
  return acc
}, [])

const atRecordEditUpdateProperty = (state, payload) => {
  const atRecordEditor = {
    atRecord:Object.assign({}, state.dialogs.atRecordEditor.atRecord, {[payload.propertyName]:payload.val})
  }

  switch(payload.propertyName) {
    case 'atTypeId' :
      atRecordEditor.atTypes = removeNotSetAtType(state.dialogs.atRecordEditor.atTypes)
      break
    case 'locationId' :
      atRecordEditor.locations = findAllBy(state.locations, 'facilityId', state.dialogs.atRecordEditor.atRecord.facilityId)
      atRecordEditor.location = findOneBy(state.locations, 'id', payload.val)
      break
    /*case 'uTime' :
      atRecordEditor.atRecord.bellSchedulePeriodId = ID_ZERO
      break */
    default :
  }
  return newState(state, 'dialogs.atRecordEditor', atRecordEditor)
}


const bellRulesEdit = (state, payload) => {
  /**
   * Developer note - code for bell rules computation of atReasons based on time commented out but left in case needed in future. 2019-07-22
   */
  const bellSchedule = Object.assign({}, payload)
  const atTypeNotSet = findOneBy(state.zeroFacility.atTypes, 'id', AT_TYPE_ID_NOT_SET)
  const atReasonNotSet = findOneBy(state.zeroFacility.atReasons, 'id', AT_REASON_ID_NOT_SET)
  const facility = findOneBy(state.facilities, 'id', bellSchedule.facilityId)
  let {atTypes, atReasons} = facility

  atTypes = sortBy.displayName(atTypes).slice()
  if(state.positiveAttendanceIsEnabled) {
    atTypes.unshift(findOneBy(state.zeroFacility.atTypes, 'id', AT_TYPE_ID_PRESENT))
  }
  atTypes.unshift(atTypeNotSet)


  const atTypeChoicesForSubPeriods = { //we need these separate so we can add 'atTypeNotSet'
    general:atTypes,
    //keeping the below, because there were instances where they would change. General is currently all that's actually needed (though we still use the below in rendering UI)
    absent:atTypes.slice(),
    absentExcused:atTypes.slice(),
    present:atTypes.filter(type => type !== atTypeNotSet),
    tardy:atTypes.slice(),
    tardyExcused:atTypes.slice(),
    outOfBuilding:atTypes.slice(),
  }

  const atReasonChoicesForSubPeriods = sortBy.displayName(atReasons)
  atReasonChoicesForSubPeriods.unshift(atReasonNotSet)

  const bellRulesEditor = {
    positiveAttendanceIsEnabled: state.positiveAttendanceIsEnabled,
    bellSchedule,
    atTypeChoicesForSubPeriods,
    atReasonChoicesForSubPeriods
  }

  return newState(state, 'dialogs.bellRulesEditor', bellRulesEditor)
}


const bellRulesEditDone = (state) => {
  return newState(state, 'dialogs.bellRulesEditor', false)
}


const bellRulesEditUpdateProperty = (state, payload) => {
  const bellRulesEditor = {
    bellSchedule:Object.assign({}, state.dialogs.bellRulesEditor.bellSchedule, {[payload.propertyName]:payload.val}),
    atTypeChoicesForSubPeriods:Object.assign({}, state.dialogs.bellRulesEditor.atTypeChoicesForSubPeriods)
  }

  switch(payload.propertyName) {
    case 'paMode' :
    case 'isOutOfBuildingPropagationEnabled':
    case 'atTypeAbsentId' :
    case 'isPaEnabled' :
    /* leaving this here as an example of how to do a low-cost removal of 'not set' from the select list if STA ever wants to do that again.
    bellRulesEditor.atTypeChoicesForSubPeriods.absent = removeNotSetAtType(bellRulesEditor.atTypeChoicesForSubPeriods.absent)
    break
    */
    case 'atTypePresentId' :
    case 'atTypeTardyId' :
    case 'atTypeTardyExcusedId' :
    case 'atTypeAbsentExcusedId' :
    case 'atTypeOutOfBuildingId' :
    case 'isNonAttendancePeriodsEnabled' :
      break
    case 'atReasonAbsentId' :
    case 'atReasonPresentId' :
    case 'atReasonTardyId' :
    case 'atReasonTardyExcusedId' :
    case 'atReasonAbsentExcusedId' :
    case 'atReasonOutId' :
    case 'atReasonDefaultId' :
      break
    case 'bellPeriodPostStartAbsentSeconds' :
    case 'bellPeriodPostStartTardySeconds' :
    case 'bellPeriodPreStartPresentSeconds' :
    case 'maxNumTardyBeforeAbsent' :
      if(parseInt(payload.val) === payload.val) {
        break
      } else {
        console.error(`bellSchedule${payload.propertyName}:${payload.val} should be an integer`)
      }
      break

    default :
      const errorMsg = `Attempt to set invalid property on Period Schedule ${payload.propertyName}:${payload.val}`;
      console.error(errorMsg)
      return state
  }
  return newState(state, `dialogs.bellRulesEditor`, bellRulesEditor)
}


const bellScheduleFetchByFaciltyIdResponse =(state,payload) => {
  let earliestStart = SECONDS_WEEK
  let latestEnd = 0

  payload.earliestStart = earliestStart
  payload.latestEnd = latestEnd
  return newState(state,'currentBellSchedule', payload)
}

const bellRulesEditSaveResponse = (state, payload) => {
  if(state.currentBellSchedule.id === payload.id) {
    return newState(state, 'currentBellSchedule', payload)
  }
  return state
}

const messageFetchResponse = (state, payload) => {
  const transitionState = newState(state, 'contentRoles', payload)

  if(state.dialogs.messageContentEditor) {
    return editContentMessage(transitionState, {val:state.dialogs.messageContentEditor.role.id})
  }
  return editContentMessage(transitionState, {val:payload[0].id})
}

const messagingEditPreviewContentResponse = (state, payload) => {
  if(state.dialogs.messageContentEditor) {
    return newState(state, 'dialogs.messageContentEditor.preview', payload)
  }
  return state
}

/**
 * Takes an object of this form to integrate more easily with UI choosers.
 * @param state
 * @param {val:contentRoleId}
 * @returns {*|*}
 */
const editContentMessage = (state, {val:contentRoleId}) => {
  const role = findOneBy(state.contentRoles, 'id', contentRoleId)
  const messageContentEditor = {
    role,
    preview:'make a change to preview',
    messageContent: Object.assign({}, role.messageContents[0]),
    isDirty:false
  }
  return newState(state, 'dialogs.messageContentEditor', messageContentEditor)
}

const messagingEditContent = (state, {messagingContentId, messageContent}) => {
  const {messageContentEditor} = state.dialogs
  if(messagingContentId !== messageContentEditor.messageContent.id) {
    throw Error(`Message content ids do not match when editing. ${messagingContentId} !== ${messageContentEditor.messageContent.id}`)
  }
  return newState(
    newState(state, 'dialogs.messageContentEditor.messageContent.content', messageContent),
    `dialogs.messageContentEditor.isDirty`,
    true)
}

const messagingEditContentStyle = (state, {messagingContentId, contentStyle}) => {
  const {messageContentEditor} = state.dialogs
  if(messagingContentId !== messageContentEditor.messageContent.id) {
    throw Error(`Message content ids do not match when editing. ${messagingContentId} !== ${messageContentEditor.messageContent.id}`)
  }
  return newState(
    newState(state, 'dialogs.messageContentEditor.messageContent.contentStyle', contentStyle),
    `dialogs.messageContentEditor.isDirty`,
    true)
}

const messagingEditSaveContentResponse = (state, messageContent) => {
  const contentRoles = state.contentRoles.map(contentRole => {
    if(contentRole.id === messageContent.contentRoleId) {
      return Object.assign({}, contentRole , {messageContents:[messageContent]})
    }
    return contentRole
  })
  return newState(state, {contentRoles})
}

const updateGeneralSetting = (state, data) => {

  if(data.propertyName === 'licensingJson.positiveAttendanceIsEnabled' && state.admin.piratesAndDragonsEnabled) {
    const licensingJson = Object.assign({}, state.settings.licensingJson, {positiveAttendanceIsEnabled:data.val})
    return newState(state, 'settings', {licensingJson, isDirty:true})
  }

  if(data.propertyName === 'licensingJson.ppkIsEnabled' && state.admin.piratesAndDragonsEnabled) {
    const licensingJson = Object.assign({}, state.settings.licensingJson, {ppkIsEnabled:data.val})
    return newState(state, 'settings', {licensingJson, isDirty:true})
  }

  console.log(data.propertyName, data.val)
  return newState(state, `settings`, {[data.propertyName]: data.val, isDirty:true})
}

const showLoginDialog = (state, payload) => {
  return newState(state, `dialogs.login`, payload)
}

const userAccountSearch = (state, payload) => {
  return newState(state, `userAccountSearchPage.search.searchString`, payload.searchString )
}
const userAccountSearchResponse = (state, payload) => {
  const statusMessage = (payload.pagination.rowCount > 0)? `Showing ${payload.results.length} of ${payload.pagination.rowCount} users.` : 'No users found.'
  return newState(state, 'userAccountSearchPage', {statusMessage, searchResults: payload.results})
}


const userAccountEdit = (state, id) => {
  let userAccount

  if(!id) {
    if(!state.currentFacilityId) {
      console.error('Cannot create a new user when in All Facilities view ')
      return state
    }
    userAccount = UserAccount({facilityId: state.currentFacilityId})
  } else {
    userAccount = findOneBy(state.userAccountSearchPage.searchResults, 'id', id)
  }

  if(!userAccount) {
    alert(`Could not find user ${id} in search results`)
    return state
  }
  return newState(state, 'dialogs.userAccountEditor', {userAccount, isDirty:false})
}

const userAccountOpenUploader = (state) => {
  if(!state.currentFacilityId) {
    console.error('Cannot upload users when in All Facilities view ')
    return state
  }
  //user account acts as a template for all uploaded users
  return newState(state, 'dialogs.userAccountUploader', {
    userAccount: UserAccount({
      facilityId: state.currentFacilityId,
      securityRoles: [SECURITY_ROLE_STAFF],
      newUserFLEs:[] //taking advantage of the permissive nature of UserAccount
    }),
  })
}

const userAccountCloseUploader = (state) => {
  return newState(state, 'dialogs.userAccountUploader', false)
}

const userAccountEditDone = (state) => {
  return newState(state, 'dialogs.userAccountEditor', false)
}

const userAccountEditSaveResponse = (state, userAccount) => {
  if(!userAccount.isDeleted) {
    return newState(state,
      'userAccountSearchPage.searchResults',
      putOneBy(state.userAccountSearchPage.searchResults, 'id', userAccount, true)
    )
  } else {
    return newState(state,
      'userAccountSearchPage.searchResults',
      state.userAccountSearchPage.searchResults.reduce((acc, item) => {
        if(item.id !== userAccount.id) {
          acc.push(item)
        }
        return acc
      }, [])
    )
  }
}

const userAccountEditUpdateProperty = (state, payload) => {
  const dirtyState = newState(state, 'dialogs.userAccountEditor.isDirty', true)
  let {facilityIds, buildingIds} = state.dialogs.userAccountEditor.userAccount.securityAccess
  const stateFragment = {}

  switch (payload.propertyName) {
    case 'securityAccess.facilityId' :
      if(!payload.val.isEmpowered) {
        if(state.dialogs.userAccountEditor.userAccount.facilityId === payload.val.id) return state
        facilityIds = facilityIds.reduce((acc, id) => {
          if (id !== payload.val.id) {
            acc.push(id)
          }
          return acc
        }, [])
      } else {
        facilityIds = facilityIds.slice()
        if(!facilityIds.includes(payload.val.id)) facilityIds.push(payload.val.id)
      }
      break
    case 'securityAccess.buildingId' :
      if(!payload.val.isEmpowered) {
        buildingIds = buildingIds.reduce((acc, id) => {
          if (id !== payload.val.id) {
            acc.push(id)
          }
          return acc
        }, [])
      } else {
        buildingIds = buildingIds.slice()
        if(!buildingIds.includes(payload.val.id)) buildingIds.push(payload.val.id)
      }
      break
    case 'facilityId' :
      facilityIds = [payload.val]
      //fallthrough ok      
    default :
      if(payload.propertyName === 'securityRoles'){
        stateFragment[payload.propertyName] = [payload.val]
      }
      else{
        stateFragment[payload.propertyName] = payload.val
      }      
  }
  stateFragment.securityAccess = {facilityIds, buildingIds}

  return newState(dirtyState, 'dialogs.userAccountEditor.userAccount', stateFragment)
}

const userAccountUploadUpdateProperty = (state, payload) => {
  const dirtyState = newState(state, 'dialogs.userAccountUploader.isDirty', true)
  let {facilityIds, buildingIds} = state.dialogs.userAccountUploader.userAccount.securityAccess
  const stateFragment = {}

  switch (payload.propertyName) {
    case 'securityAccess.facilityId' :
      if(!payload.val.isEmpowered) {
        if(state.dialogs.userAccountUploader.userAccount.facilityId === payload.val.id) return state
        facilityIds = facilityIds.reduce((acc, id) => {
          if (id !== payload.val.id) {
            acc.push(id)
          }
          return acc
        }, [])
      } else {
        facilityIds = facilityIds.slice()
        if(!facilityIds.includes(payload.val.id)) facilityIds.push(payload.val.id)
      }
      break
    case 'securityAccess.buildingId' :
      if(!payload.val.isEmpowered) {
        buildingIds = buildingIds.reduce((acc, id) => {
          if (id !== payload.val.id) {
            acc.push(id)
          }
          return acc
        }, [])
      } else {
        buildingIds = buildingIds.slice()
        if(!buildingIds.includes(payload.val.id)) buildingIds.push(payload.val.id)
      }
      break
    case 'facilityId' :
      facilityIds = [payload.val]
      //fallthrough ok
    default :
      if(payload.propertyName === 'securityRoles'){
        stateFragment[payload.propertyName] = [payload.val]
      }
      else{
        stateFragment[payload.propertyName] = payload.val
      }  
  }
  stateFragment.securityAccess = {facilityIds, buildingIds}

  return newState(dirtyState, 'dialogs.userAccountUploader.userAccount', stateFragment)
}

const workerJobEdit = (state, id) => {
  return newState(state, `dialogs.workerJob`, findOneBy(state.workerJobs, 'id', id))
}

const workerJobEditUpdateProperty = (state, payload) => {
  const {propertyName, val} = payload
  if(propertyName.indexOf('.')) {
    return newState(state, `dialogs.workerJob.${propertyName}`, val)
  }
  return newState(state, `dialogs.workerJob`, {[propertyName]:val})
}

const workerJobEditSaveResponse = (state, payload) => {
  return newState(state, {workerJobs: state.workerJobs.map(( oldJob ) => (oldJob.id === payload.id)? payload : oldJob)})
}

const workerJobEditDone = (state) => {
  return newState(state, 'dialogs.workerJob', false)
}


const workerJobViewLogs = (state, workerJobWithLogs) => {
  workerJobWithLogs.logs.sort((a, b) => a.id > b.id? -1: b.id > a.id? 1: 0)
  return newState(state, `dialogs.workerJobLogs`, workerJobWithLogs)
}

const workerJobViewLogsToggleExpandDetail = (state, id) => {
  const logs = state.dialogs.workerJobLogs.logs.map(log => {
    if(log.id === id) {
      log.expand = !log.expand
    }
    return log
  })
  return newState(state, `dialogs.workerJobLogs.logs`, logs)
}


const workerJobViewLogsDone = (state) => {
  return newState(state, 'dialogs.workerJobLogs', false)
}

const fetchHomePageDataResponse = (state, payload) => {
  const now = (state.currentItemId.length > 0)? moment(state.currentItemId) : moment()
  const todayYMD = now.format(FORMAT_YMD)
  const recentPoll = JSON.stringify(payload)

  if(state.homePage.lastPollJSON === recentPoll) {
    if(state.homePage.todayYMD === todayYMD) { //needed to keep the current date updating on the home page when the calendar is switching
      return state
    }
    newState(state, 'homePage.todayYMD', todayYMD)
  }

  const protoCounts = {
    [AT_TYPE_ID_TARDY]:0,
    [AT_TYPE_ID_ABSENT]:0,
    [AT_TYPE_ID_TARDY_EXCUSED]:0,
    [AT_TYPE_ID_ABSENT_EXCUSED]:0,
    [AT_TYPE_ID_PRESENT]:0,
    [AT_TYPE_ID_OUT]:0,
    [AT_TYPE_ID_OUT_OF_BUILDING]:0,
    [AT_TYPE_ID_UNKNOWN]:0,
    [AT_TYPE_ID_SKYWARD_CUSTOM]:0
  }


  const weekday = now.weekday()
  const weekdays = {}
  for(let i = 0; i < 7; i++) {
    weekdays[i] = Object.assign({}, protoCounts)
    weekdays[i].name = moment(now).weekday(i).format(FORMAT_WEEKDAY)
  }


  const homePage = payload.reduce((acc, atRecord) => {
    const atTypeId = atRecord.atTypeId > GENERIC_AT_TYPES_IN_DATABASE_COUNT ? AT_TYPE_ID_SKYWARD_CUSTOM : atRecord.atTypeId //all specific attendance types will be shown under a single category 'Specific' with id = 11
    const mo = moment.unix(atRecord.uTime)
    acc.weekdays[mo.weekday()][atTypeId]++
    if(mo.format(FORMAT_YMD) === todayYMD) {
      const hour = mo.hour()
      if(!acc.dayByHour.hasOwnProperty(hour)) {
        acc.dayByHour[hour] = Object.assign({}, protoCounts)
      }
      acc.dayByHour[hour][atTypeId]++
    }
    return acc
  }, {
    lastPollJSON:recentPoll,
    dayByHour:{},
    todayYMD,
    weekdays,
    todayWeekdayName: now.format(FORMAT_WEEKDAY)
  })
  homePage.dayTotals = homePage.weekdays[weekday]

  return newState(state, 'homePage', homePage)
}

const reportTypesFetchResponse = (state, payload) => {
  let reportTypes = payload.reduce((acc, item) => {if(item.isVisibleInAdmin && item.isEnabled) {acc.push(item) }return acc}, [])
  reportTypes = sortBy.displayOrderIndex(reportTypes)
  reportTypes.unshift({id: ID_ZERO, displayName: "Not Set", jsbCustomConfiguration:{settingNames:[]}})
  const reportTypesById = indexify(reportTypes, 'id')
  return newState(state, 'reportPage', {reportTypes, reportTypesById, jsbCustomConfiguration:false})
}

const reportPageEditUpdateProperty = (state, payload) => {
  const reportPage = {report:false}
  switch(payload.propertyName) {
    case 'reportTypeId' :
      reportPage.reportTypeId = payload.val
      if(payload.val === ID_ZERO) {
        reportPage.jsbCustomConfiguration = false
      } else {
        reportPage.jsbCustomConfiguration = JSON.parse(JSON.stringify(state.reportPage.reportTypesById(payload.val).jsbCustomConfiguration))
      }
      break
    case 'lastDateYMD' :
    case 'firstDateYMD' :
      reportPage[payload.propertyName] = payload.val
    //no default
  }
  if(payload.propertyName.startsWith('jsbCustomConfiguration')) {
    return newState(state, `reportPage.${payload.propertyName}`, payload.val)
  }
  return newState(state, 'reportPage', reportPage)
}

const reportPageFetchReportByIdResponse = (state, payload) => {
  return newState(state, 'reportPage.report', payload)
}

const dangerousUpdateGeneralProperty = (state, payload) => {
  const {propertyName, val} = payload
  if(!propertyName) throw new Error('Property name does not exist!')
  if(typeof val === 'undefined') throw new Error('val does not exist!')
  Cookies.set(`admin.${propertyName}`, val)
  return newState(state, propertyName, val)
}

const pirateUpdateGeneralProperty = (state, payload) => {
  if(state.piratesAndDragonsEnabled) {
    const {propertyName, val} = payload
    if (!propertyName) throw new Error('Property name does not exist!')
    if (typeof val === 'undefined') throw new Error('val does not exist!')

    if (!isA(val, 'object')) {
      Cookies.set(`pirate.${propertyName}`, val)
    } else {
      const item = pathify(val)
      Object.keys(item).forEach(key => {
        Cookies.set(`pirate.${propertyName}.${key}`, item[key])
      })
    }

    return newState(state, `pirate.${propertyName}`, val)
  }
  return state
}

const dangerousUpdateClearGeneralPropertyCookies = (state) => {
  const dangerouslyUpdatedGeneralProperties = Cookies.get()
  reduce(
    dangerouslyUpdatedGeneralProperties,
    (acc, val, key) => {
      if(key.startsWith('admin.')) {
        Cookies.remove(key)
      }
      if(key.startsWith('pirate.')) {
        Cookies.remove(key)
      }
      return acc
    },
    {}
  )
  console.warn('Admin Cookies should be cleared. Reload the page to see the effect.')
  return state
}

const buildingChooseDone = (state) => {
  return newState(state, {buildingChooser:{buildings:false}})
}

const buildingChooseConfirmResponse = (state, {enabledBuildings}) => {
  let {buildings} = state
  let {allBuildings} = state
  let {byId} = state
  let {newBuildingLocationsSyncWait} = state

  buildings = buildings.concat(enabledBuildings)
  buildings = sortBy.displayName(buildings.reduce(zeroReducer, []))

  allBuildings = allBuildings.concat(enabledBuildings)

  let buildingsWithZero = [byId.building(ID_ZERO)].concat(buildings)
  byId.building = indexify(buildingsWithZero, 'id')

  let permittedBuildings = getPermittedBuildings(state, buildings)

  newBuildingLocationsSyncWait.buildingsIds = newBuildingLocationsSyncWait.buildingsIds.concat(enabledBuildings.map(building => building.id))

  return newState(state, {buildingChooser:{buildings:false}, buildings, allBuildings, byId, permittedBuildings, newBuildingLocationsSyncWait})
}

const locationsSyncWaitResponse = (state, {newLocations, buildingIds}) => {
  let {newBuildingLocationsSyncWait} = state
  let {byId} = state

  newBuildingLocationsSyncWait.buildingsIds = newBuildingLocationsSyncWait.buildingsIds.filter(id => !buildingIds.includes(id))

  let updateObject = {newBuildingLocationsSyncWait}

  if(newLocations){    
    const locations = sortList('displayName')(newLocations)
    byId.location = indexify(locations, 'id')
    byId.locationsByBuilding = indexifyArrays(locations, 'buildingId')

    updateObject = {...updateObject, locations, byId}
  }

  return newState(state, updateObject)
}

const entityChooserSetIsEnabled = (state, {id, isEnabled}) => {
  const entities = state.entityChooser.entities.map(entity => {
    if(entity.id === id) {
      return Object.assign(entity, {isEnabled:isEnabled})
    }
    return entity
  })
  return newState(state, {entityChooser:{entities: entities}})
}

const entityChooserOpenResponse = (state, entities) => {
  return newState(state, 'entityChooser', {entities})
}

const buildingChooserOpenResponse = (state, buildings) => {
  return newState(state, 'buildingChooser', {buildings})
}

const buildingChooserSetIsEnabled = (state, {id, isEnabled}) => {
  const buildings = state.buildingChooser.buildings.map(building => {
    if(building.id === id) {
      return Object.assign(building, {isEnabled:isEnabled})
    }
    return building
  })
  return newState(state, {buildingChooser:{buildings}})
}

const attendanceMonitorCoursesEdit = (state, payload) => {
  const courses = state.courses.map(course => ({ ...course }));
  let updatedState = newState(state, 'attendanceMonitorCoursesChooser.courses', courses, false);
  updatedState = newState(updatedState, `dialogs.attendanceMonitorCoursesEditor`, true);
  return updatedState;
};

const attendanceMonitorCoursesEditDone = (state, payload) => {
  return newState(state, `dialogs.attendanceMonitorCoursesEditor`, false);
};

const coursesFetchResponse = (state, courses) => {
   const copiedCourses = courses.map(course => ({ ...course }));
  let updatedState = newState(state, 'courses', courses, false);
  updatedState = newState(updatedState, 'attendanceMonitorCoursesChooser.courses', copiedCourses, false);
  return updatedState;
};

const attendanceMonitorChooserCoursesResponse = (state, courses) => {
  return newState(state, 'attendanceMonitorCoursesChooser.courses', courses, false);
};

const attendanceMonitorCoursesSaveResponse = (state, courses) => {
  return newState(state, {courses});
};

const attendanceMonitorCoursesChooserSetIsEnabled = (state, {id, isEnabled}) => {
  const courses = state.attendanceMonitorCoursesChooser.courses.map(course => {
    if(course.id === id) {
      return Object.assign(course, {isAttendanceMonitorEnabled:isEnabled});
    }
    return course;
  })
  return newState(state, {attendanceMonitorCoursesChooser: {courses}});
};

const attendanceMonitorLocationsEditDone = (state, payload) => {
  return newState(state, `dialogs.attendanceMonitorLocationsEditor`, false);
};

const attendanceMonitorLocationsEdit = (state, payload) => {
  const locations = state.allLocations.map(location => ({ ...location }));
  let updatedState = newState(state, 'attendanceMonitorLocationsChooser.locations', locations, false);
  updatedState = newState(updatedState, `dialogs.attendanceMonitorLocationsEditor`, true);
  return updatedState;
};

const locationsFetchResponse = (state, locations) => {
  const copiedLocations = locations.map(location => ({ ...location }));
  let updatedState = newState(state, 'allLocations', locations, false);
  updatedState = newState(updatedState, 'attendanceMonitorLocationsChooser.locations', copiedLocations, false);
  return updatedState;
};

const attendanceMonitorChooserLocationsResponse = (state, locations) => {
  return newState(state, 'attendanceMonitorLocationsChooser.locations', locations, false);
};

const attendanceMonitorLocationsChooserSetIsEnabled = (state, {id, isEnabled}) => {
  const locations = state.attendanceMonitorLocationsChooser.locations.map(location => {
    if(location.id === id) {
      return Object.assign(location, {isAttendanceMonitorEnabled:isEnabled});
    }
    return location;
  })
  return newState(state, {attendanceMonitorLocationsChooser: {locations: locations}});
};

const attendanceMonitorLocationsSaveResponse = (state, locations) => {
  return newState(state, {allLocations: locations});
};

const schoolYearsFetchResponse = (state, schoolYearsResponse) => {
  const schoolYearsData = {
    schooltrakCurrent: schoolYearsResponse.currentSchoolTrakSchoolYear,
    skywardCurrent: schoolYearsResponse.currentSkywardSchoolYear
  };
  return newState(state, 'schoolYears', schoolYearsData, false);
};

const migrateSchoolYearResponse = (state, schoolYear) => {
  const schoolYearMigration = {
    status: "success",
    message: ""
  };
  let updatedState = newState(state, 'schoolYearMigration', schoolYearMigration, false);
  updatedState = newState(updatedState, 'schoolYear', schoolYear, false);
  return updatedState;
};

const migrateSchoolYearError = (state, response) => {
  const schoolYearMigration = {
    status: "error",
    message: response.userMessage
  };
  return newState(state, 'schoolYearMigration', schoolYearMigration, false);
};

const kioskFacilitiesCustomSetupPPKEdit = (state, payload) => {
  const existingFacilitiesOverrides = state.dialogs.ppkEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides;
  const facilities = state.facilities; 

  const facilitiesOverrides = facilities.map(facility => {
    const facilityOverride = existingFacilitiesOverrides.find(o => o.facilityId === facility.id) || {};
    const overrideTypeId = facilityOverride.overrideTypeId || 0;  
    return { facilityId: facility.id, overrideTypeId };
  });

  return {
      ...state,
      kioskFacilitiesCustomSetupChooser: {
          ...state.kioskFacilitiesCustomSetupChooser,
          facilitiesOverrides
      },
      dialogs: {
          ...state.dialogs,
          kioskFacilitiesCustomSetupEditor: {kioskType: KIOSK_TYPE_PPK}
      }
  };
};

const kioskFacilitiesCustomSetupWebEdit = (state, payload) => {
  const existingFacilitiesOverrides = state.dialogs.kioskConfigurationEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides;
  const facilities = state.facilities; 

  const facilitiesOverrides = facilities.map(facility => {
    const facilityOverride = existingFacilitiesOverrides.find(o => o.facilityId === facility.id) || {};
    const overrideTypeId = facilityOverride.overrideTypeId || 0;  
    return { facilityId : facility.id, overrideTypeId };
  });

  return {
      ...state,
      kioskFacilitiesCustomSetupChooser: {
          ...state.kioskFacilitiesCustomSetupChooser,
          facilitiesOverrides
      },
      dialogs: {
          ...state.dialogs,
          kioskFacilitiesCustomSetupEditor: {kioskType: KIOSK_TYPE_WEB}
      }
  };
};

const kioskFacilitiesCustomSetupEditDone = (state, payload) => {
  return newState(state, `dialogs.kioskFacilitiesCustomSetupEditor`, false);
};

const kioskFacilitiesCustomSetupUpdateProperty = (state, payload) => {
  const { facilityId, propertyName, val } = payload;
  const facilitiesOverrides = state.kioskFacilitiesCustomSetupChooser.facilitiesOverrides.map(facilityOverride => {
      if (facilityOverride.facilityId === facilityId) {
          return { ...facilityOverride, [propertyName]: val, };
      }
      return facilityOverride;
  });

  const kioskFacilitiesCustomSetupChooser = {
    facilitiesOverrides: facilitiesOverrides
  }
  return newState(state, 'kioskFacilitiesCustomSetupChooser', kioskFacilitiesCustomSetupChooser);
};

const kioskFacilitiesCustomSetupApply = (state, payload) => {
  const kioskType = state.dialogs.kioskFacilitiesCustomSetupEditor.kioskType;
  const chooserFacilitiesOverrides = state.kioskFacilitiesCustomSetupChooser.facilitiesOverrides;

  let editorFacilitiesOverrides;
  if(kioskType === KIOSK_TYPE_PPK){
    editorFacilitiesOverrides = state.dialogs.ppkEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides || [];
  } else {
    editorFacilitiesOverrides = state.dialogs.kioskConfigurationEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides || []
  }

  const updatedEditorFacilitiesOverrides = chooserFacilitiesOverrides.reduce((acc, chooserOverride) => {
    if (chooserOverride.overrideTypeId === 0) {
      return acc.filter(item => item.facilityId !== chooserOverride.facilityId);
    }
    const existingEditorOverride = editorFacilitiesOverrides.find(editor => editor.facilityId === chooserOverride.facilityId);
    if (!existingEditorOverride && chooserOverride.overrideTypeId > 0) {
      return acc.concat({ ...chooserOverride, });
    } else if (existingEditorOverride && existingEditorOverride.overrideTypeId !== chooserOverride.overrideTypeId) {
      return acc.map(editor => {
        if (editor.facilityId === chooserOverride.facilityId) {
          return {
            ...editor,
            overrideTypeId: chooserOverride.overrideTypeId
          };
        }
        return editor;
      });
    }
    return acc;
  }, editorFacilitiesOverrides.slice());

  if(kioskType === KIOSK_TYPE_PPK){
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        ppkEditor: {
          ...state.dialogs.ppkEditor,
          kiosk: {
            ...state.dialogs.ppkEditor.kiosk,
            customAttendanceConfiguration:{
              facilitiesOverrides: updatedEditorFacilitiesOverrides 
            }
          }
        },
        kioskFacilitiesCustomSetupEditor: false
      }
    };
  }
  else{
    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        kioskConfigurationEditor: {
          ...state.dialogs.kioskConfigurationEditor,
          kiosk: {
            ...state.dialogs.kioskConfigurationEditor.kiosk,
            customAttendanceConfiguration:{
              facilitiesOverrides: updatedEditorFacilitiesOverrides 
            }
          }
        },
        kioskFacilitiesCustomSetupEditor: false
      }
    };
  }
};

const kioskFacilitiesCustomSetupUpdateEditorProperty = (state, payload) => {
  const { facilityId, propertyName, val, kioskType } = payload;
  
  let editorFacilitiesOverrides;
  if(kioskType === KIOSK_TYPE_PPK){
    editorFacilitiesOverrides = state.dialogs.ppkEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides || [];
  } else {
    editorFacilitiesOverrides = state.dialogs.kioskConfigurationEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides || []
  }

  const facilitiesOverrides = editorFacilitiesOverrides.map(facilityOverride => {
    if (facilityOverride.facilityId === facilityId) {
      return { ...facilityOverride, [propertyName]: val, };
    }
    return facilityOverride;
  });
  
  if(kioskType === KIOSK_TYPE_PPK){
    return newState(state, 'dialogs.ppkEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides', facilitiesOverrides);
  } else {
    return newState(state, 'dialogs.kioskConfigurationEditor.kiosk.customAttendanceConfiguration.facilitiesOverrides', facilitiesOverrides);
  }
}

const overridableAtTypesEditDone = (state, payload) => {
  return newState(state, `dialogs.overridableAttendanceTypesEditor`, false);
};

const overridableAtTypesSaveResponse = (state, updatedAtTypes) => {
   const atTypes = state.atTypes.map(stateAtType => {
    const atType = updatedAtTypes.find(atType => atType.id === stateAtType.id);
    if (atType) {
      return {
        ...stateAtType,
        kioskCanOverride: atType.kioskCanOverride
      };
    }
    return stateAtType;
  });
  return {
    ...state,
    atTypes
  };
};

const overridableAtTypesEdit = (state, payload) => {
  const facilityId = state.currentFacilityId
  const facilityAtTypes = state.atTypes
    .filter(atType => atType.facilityId === facilityId)
    .map(atType => ({ ...atType }));
  let updatedState = newState(state, 'overridableAttendanceTypesChooser.atTypes', facilityAtTypes, false);
  updatedState = newState(updatedState, `dialogs.overridableAttendanceTypesEditor`, true);
  return updatedState;
};

const overridableAtTypesChooserSetIsEnabled = (state, {id, isEnabled}) => {
  const atTypes = state.overridableAttendanceTypesChooser.atTypes.map(atType => {
    if(atType.id === id) {
      return Object.assign(atType, {kioskCanOverride:isEnabled});
    }
    return atType;
  })
  return newState(state, {overridableAttendanceTypesChooser: {atTypes: atTypes}});
};

const semesterRuleEdit = (state, payload) => {
  const semesterRuleEditor = {
    attendanceTerms: payload.attendanceTerms.map(term => ({ ...term }))
  }
  return newState(state, 'dialogs.semesterRuleEditor', semesterRuleEditor)
}

const semesterRuleFetchByFaciltyIdResponse =(state,payload) => {
  const currentSemesterRuleSummary = {
    attendanceTerms:payload
  };
  return newState(state,'currentSemesterRuleSummary', currentSemesterRuleSummary)
}

const semesterRuleEditDone = (state) => {
  return newState(state, 'dialogs.semesterRuleEditor', false)
}

const semesterRuleEditSaveResponse = (state, payload) => {
  const updatedAttendanceTermsMap = new Map(
    payload.attendanceTerms.map(term => [term.id, term])
  );

  const updatedAttendanceTerms = state.currentSemesterRuleSummary.attendanceTerms.map(term => {
    if (updatedAttendanceTermsMap.has(term.id)) {
      return updatedAttendanceTermsMap.get(term.id);
    }
    return term;
  });

  const currentSemesterRuleSummary = {
    ...state.currentSemesterRuleSummary,
    attendanceTerms: updatedAttendanceTerms
  };
  return newState(state, 'currentSemesterRuleSummary', currentSemesterRuleSummary);
};


const attendanceTermSetIsCountResetEnabled = (state, {id, isAttendanceCountResetEnabled}) => {
  const attendanceTerms = state.dialogs.semesterRuleEditor.attendanceTerms.map(term => {
    if(term.id === id) {
      return Object.assign(term, {isAttendanceCountResetEnabled:isAttendanceCountResetEnabled});
    }
    return term;
  })

  const semesterRuleEditor = {
    attendanceTerms
  };
  return newState(state, 'dialogs.semesterRuleEditor', semesterRuleEditor);
};

const adminRemoveSkywardState = (state) => {
  return newState(state, 'skywardInfo', false);
}

const AdminReducer = {
  [ADMIN_IS_READY]: (state) => newState(newState(state, {isReady:true}), `dialogs.login`, false),
  [ADMIN_SET_CONFIGURATION]: payloadOnly(setConfiguration),
  [KIOSK_INSTANCE_EDIT] : payloadOnly(kioskInstanceEdit),
  [ADMIN_SEARCH_LOCATIONS_BY]: payloadOnly(searchLocationsBy),
  [ADMIN_SET_CURRENT_PATH]: payloadOnly(setCurrentPath),
  [ADMIN_SET_LOCATION_PAGE_INITAL_DATA]: payloadOnly(setLocationPageData),
  [ADMIN_SET_LOCATION_PAGE_KIOSKS]: payloadOnly(setLocationPageKiosks),
  [ADMIN_EDIT_LOCATION_SETTINGS] : payloadOnly(editCurrentLocationDefaultConfiguration),
  [ADMIN_CHANGE_KIOSK_SETTING] : payloadOnly(changeKioskSetting),
  [ADMIN_CLOSE_KIOSK_EDITOR] : payloadOnly(closeKioskEditor),
  [ADMIN_SNACK_MESSAGE_SHOW] : payloadOnly(snackMessageShow),
  [ADMIN_SNACK_MESSAGE_HIDE] : payloadOnly(snackMessageHide),
  [ADMIN_SAVE_KIOSK_SETTINGS_RESPONSE] : payloadOnly(saveKioskSettingsResponse),
  [ADMIN_SAVE_LOCATION_SETTINGS_RESPONSE] : payloadOnly(saveLocationSettingsResponse),
  [ADMIN_SEARCH_STUDENTS] : payloadOnly(updateSearchStudentParams),
  [ADMIN_SEARCH_LOCATOR_STUDENTS] : payloadOnly(updateSearchLocatorStudentParams),
  [ADMIN_SELECT_LOCATOR_STUDENT] : payloadOnly(selectLocatorStudent),
  [ADMIN_SET_LOCATOR_STUDENT_INFO] : payloadOnly(setLocatorStudentInfo),
  [ADMIN_SET_LOCATOR_SCHEDULE_PICKER_DATE] : payloadOnly(setLocatorSchedulePickerDate),
  [ADMIN_SET_LOCATOR_SCHEDULE_PERIODS] : payloadOnly(setLocatorSchedulePeriods),
  [ADMIN_SEARCH_STUDENTS_RESPONSE] : payloadOnly(searchStudentsResponse),
  [ADMIN_SEARCH_LOCATOR_STUDENTS_RESPONSE] : payloadOnly(searchLocatorStudentsResponse),
  [ADMIN_RESET_SEARCH_LOCATOR_STUDENTS_RESPONSE] : payloadOnly(resetSearchLocatorStudentsResponse),
  [ADMIN_SET_SYNC_QUEUES_RESPONSE] : payloadOnly(setSyncQueuesResponse),
  [ADMIN_STUDENTS_SEARCH_STATUS_MESSAGE] : payloadOnly(updateStudentSearchStatus),
  [ADMIN_APP_SETTINGS_FETCH_RESPONSE] : payloadOnly(appSettingsFetchResponse),
  [STUDENT_DETAIL_PAGE_SET_INITIAL_DATA] : payloadOnly(studentDetailPageSetInitialData),
  [SET_STUDENT_PAGE_SCHEDULE_ATTENDANCE] : payloadOnly(setStudentPageScheduleAttendance),
  [SET_STUDENT_PAGE_LIST_ATTENDANCE] : payloadOnly(setStudentPageListAttendance),
  [SET_STUDENT_DETAIL_PAGE_ATTENDANCE_VIEW] : payloadOnly(setStudentDetailPageAttendanceView),
  [STUDENT_DETAIL_PAGE_REPORT_EDIT_UPDATE_PROPERTY] : payloadOnly(studentDetailPageReportEditUpdateProperty),
  [STUDENT_DETAIL_PAGE_REPORT_FETCH_ATTENDANCE_LOG_RESPONSE] : payloadOnly(studentDetailPageReportFetchAttendanceLogResponse),
  [STUDENT_DETAIL_TOGGLE_SHOW_REPORTING_ONLY] : payloadOnly(studentDetailPageToggleShowReportingOnly),
  [SET_SCHEDULE_DISPLAY_SETTINGS] : payloadOnly(setSchedDispSettings),
  [ADMIN_AT_RECORD_EDIT] : payloadOnly(atRecordEdit),
  [ADMIN_AT_RECORD_EDIT_DONE] : atRecordEditDone,
  [ADMIN_AT_RECORD_EDIT_UPDATE_PROPERTY] : payloadOnly(atRecordEditUpdateProperty),
  [ADMIN_BELL_SCHEDULE_FETCH_BY_FACILITY_ID_RESPONSE] : payloadOnly(bellScheduleFetchByFaciltyIdResponse),
  [ADMIN_BELL_RULES_EDIT] : payloadOnly(bellRulesEdit),
  [ADMIN_BELL_RULES_EDIT_DONE] : payloadOnly(bellRulesEditDone),
  [ADMIN_BELL_RULES_EDIT_UPDATE_PROPERTY] : payloadOnly(bellRulesEditUpdateProperty),
  [ADMIN_BELL_RULES_EDIT_SAVE_RESPONSE] : payloadOnly(bellRulesEditSaveResponse),
  [ADMIN_MESSAGING_FETCH_RESPONSE] : payloadOnly(messageFetchResponse),
  [MESSAGING_EDIT_PREVIEW_CONTENT_RESPONSE] : payloadOnly(messagingEditPreviewContentResponse),
  [ADMIN_MESSAGING_EDIT_UPDATE_CONTENT] : payloadOnly(messagingEditContent),
  [ADMIN_MESSAGING_EDIT_CHOOSE_CONTENT_MESSAGE] : payloadOnly(editContentMessage),
  [ADMIN_MESSAGING_EDIT_SAVE_CONTENT_RESPONSE] : payloadOnly(messagingEditSaveContentResponse),
  [ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET] : payloadOnly(kioskDefaultConfigurationPropSet),
  [ADMIN_KIOSK_DEFAULT_CONFIGURATION_PROP_SET_CHANGEABLE] : payloadOnly(kioskDefaultConfigurationPropSetChangeable),
  [ADMIN_KIOSK_DEFAULT_CONFIGURATION_EDIT_RESPONSE] : payloadOnly(editKioskDefaultConfiguration),
  [ADMIN_UPDATE_GENERAL_SETTING] : payloadOnly(updateGeneralSetting),
  [ADMIN_APP_SETTINGS_SAVE_RESPONSE] : payloadOnly(appSettingsFetchResponse),
  [ADMIN_LOGIN_SHOW_UI] : payloadOnly(showLoginDialog),
  [ADMIN_USER_ACCOUNT_SEARCH] : payloadOnly(userAccountSearch),
  [ADMIN_USER_ACCOUNT_SEARCH_RESPONSE] : payloadOnly(userAccountSearchResponse),
  [ADMIN_USER_ACCOUNT_EDIT] : payloadOnly(userAccountEdit),
  [ADMIN_USER_ACCOUNT_OPEN_UPLOADER] : payloadOnly(userAccountOpenUploader),
  [ADMIN_USER_ACCOUNT_CLOSE_UPLOADER] : payloadOnly(userAccountCloseUploader),
  [ADMIN_USER_ACCOUNT_EDIT_DONE] : payloadOnly(userAccountEditDone),
  [ADMIN_USER_ACCOUNT_EDIT_SAVE_RESPONSE] : payloadOnly(userAccountEditSaveResponse),
  [ADMIN_USER_ACCOUNT_EDIT_UPDATE_PROPERTY] : payloadOnly(userAccountEditUpdateProperty),
  [ADMIN_USER_ACCOUNT_UPLOAD_UPDATE_PROPERTY] : payloadOnly(userAccountUploadUpdateProperty),
  [ADMIN_MESSAGING_EDIT_UPDATE_CONTENT_STYLE] : payloadOnly(messagingEditContentStyle),
  //[ADMIN_HOME_PAGE_DATA_FETCH_RESPONSE] : payloadOnly(homePageDataFetchResponse),
  [ADMIN_APP_WORKER_JOB_FETCH_RESPONSE] : payloadOnly(appWorkerJobFetchResponse),
  [ADMIN_WORKER_JOB_EDIT] : payloadOnly(workerJobEdit),
  [ADMIN_WORKER_JOB_EDIT_DONE] : payloadOnly(workerJobEditDone),
  [ADMIN_WORKER_JOB_EDIT_SAVE_RESPONSE] : payloadOnly(workerJobEditSaveResponse),
  [ADMIN_WORKER_JOB_EDIT_UPDATE_PROPERTY] : payloadOnly(workerJobEditUpdateProperty),
  [ADMIN_COURSES_FETCH_RESPONSE] : payloadOnly(coursesFetchResponse),
  [ADMIN_AT_MONITOR_CHOOSER_COURSES_RESPONSE] : payloadOnly(attendanceMonitorChooserCoursesResponse),
  [ADMIN_AT_MONITOR_COURSES_SAVE_RESPONSE] : payloadOnly(attendanceMonitorCoursesSaveResponse),
  [ADMIN_AT_MONITOR_COURSES_EDIT] : payloadOnly(attendanceMonitorCoursesEdit),
  [ADMIN_AT_MONITOR_COURSES_EDIT_DONE] : payloadOnly(attendanceMonitorCoursesEditDone),
  [ADMIN_AT_MONITOR_COURSES_CHOOSER_SET_IS_ENABLED] : payloadOnly(attendanceMonitorCoursesChooserSetIsEnabled),
  [ADMIN_AT_MONITOR_LOCATIONS_EDIT] : payloadOnly(attendanceMonitorLocationsEdit),
  [ADMIN_AT_MONITOR_LOCATIONS_EDIT_DONE] : payloadOnly(attendanceMonitorLocationsEditDone),
  [ADMIN_LOCATIONS_FETCH_RESPONSE] : payloadOnly(locationsFetchResponse),
  [ADMIN_AT_MONITOR_CHOOSER_LOCATIONS_RESPONSE] : payloadOnly(attendanceMonitorChooserLocationsResponse),
  [ADMIN_AT_MONITOR_LOCATIONS_CHOOSER_SET_IS_ENABLED] : payloadOnly(attendanceMonitorLocationsChooserSetIsEnabled),
  [ADMIN_AT_MONITOR_LOCATIONS_SAVE_RESPONSE] : payloadOnly(attendanceMonitorLocationsSaveResponse),
  [WORKER_JOB_VIEW_LOGS] : payloadOnly(workerJobViewLogs),
  [WORKER_JOB_VIEW_LOGS_TOGGLE_EXPAND_DETAIL] : payloadOnly(workerJobViewLogsToggleExpandDetail),
  [WORKER_JOB_VIEW_LOGS_DONE] : payloadOnly(workerJobViewLogsDone),
  [FETCH_HOME_PAGE_DATA_RESPONSE] : payloadOnly(fetchHomePageDataResponse),
  [REPORT_TYPES_FETCH_RESPONSE] : payloadOnly(reportTypesFetchResponse),
  [REPORT_PAGE_EDIT_UPDATE_PROPERTY] : payloadOnly(reportPageEditUpdateProperty),
  [REPORT_PAGE_FETCH_REPORT_BY_ID_RESPONSE] : payloadOnly(reportPageFetchReportByIdResponse),
  [DANGEROUS_UPDATE_GENERAL_PROPERTY] : payloadOnly(dangerousUpdateGeneralProperty),
  [DANGEROUS_PIRATE_ACTION] : payloadOnly(dangerousUpdateGeneralProperty),
  [DANGEROUS_UPDATE_CLEAR_GENERAL_GENERAL_PROPERTY_COOKIES] : payloadOnly(dangerousUpdateClearGeneralPropertyCookies),
  [PIRATE_UPDATE_GENERAL_PROPERTY] : payloadOnly(pirateUpdateGeneralProperty),
  [BUILDING_CHOOSER_OPEN_RESPONSE] : payloadOnly(buildingChooserOpenResponse),
  [BUILDING_CHOOSER_SET_IS_ENABLED] : payloadOnly(buildingChooserSetIsEnabled),
  [BUILDING_CHOOSER_DONE] : payloadOnly(buildingChooseDone),
  [BUILDING_CHOOSE_CONFIRM_RESPONSE] : payloadOnly(buildingChooseConfirmResponse),
  [LOCATIONS_SYNC_WAIT_RESPONSE] : payloadOnly(locationsSyncWaitResponse),
  [PPK_CONFIGURATION_EDIT_PROP_SET] : payloadOnly(ppkConfigurationEditPropSet),
  [PPK_CONFIGURATION_EDIT_RESPONSE] : payloadOnly(ppkConfigurationEditResponse),
  [PPK_CONFIGURATION_EDIT_CLOSE] : payloadOnly(ppkConfigurationEditDone),
  [SINGLETON_KIOSK_EDIT_FETCH_RESPONSE] : payloadOnly(singletonKioskEditFetchResponse),
  [ENTITY_CHOOSER_OPEN_RESPONSE] : payloadOnly(entityChooserOpenResponse),
  [ENTITY_CHOOSER_SET_IS_ENABLED] : payloadOnly(entityChooserSetIsEnabled),
  [ENTITY_SET_IS_ENABLED_RESPONSE] : payloadOnly(entitySetIsEnabledResponse),
  [HIDE_ADD_ENTITIES_DIALOG] : payloadOnly(hideAddEntitiesDialog),
  [ADMIN_SCHOOL_YEARS_FETCH_RESPONSE] : payloadOnly(schoolYearsFetchResponse),
  [ADMIN_MIGRATE_SCHOOL_YEAR_RESPONSE] : payloadOnly(migrateSchoolYearResponse),
  [ADMIN_MIGRATE_SCHOOL_YEAR_ERROR] : payloadOnly(migrateSchoolYearError),
  [ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_PPK_EDIT] : payloadOnly(kioskFacilitiesCustomSetupPPKEdit),
  [ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_WEB_EDIT] : payloadOnly(kioskFacilitiesCustomSetupWebEdit),
  [ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE] : payloadOnly(kioskFacilitiesCustomSetupEditDone),
  [ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY] : payloadOnly(kioskFacilitiesCustomSetupUpdateProperty),
  [ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_APPLY] : payloadOnly(kioskFacilitiesCustomSetupApply),
  [ADMIN_KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_EDITOR_PROPERTY] : payloadOnly(kioskFacilitiesCustomSetupUpdateEditorProperty),
  [ADMIN_OVERRIDABLE_AT_TYPES_EDIT_DONE] : payloadOnly(overridableAtTypesEditDone),
  [ADMIN_OVERRIDABLE_AT_TYPES_RESPONSE] : payloadOnly(overridableAtTypesSaveResponse),
  [ADMIN_OVERRIDABLE_AT_TYPES_EDIT] : payloadOnly(overridableAtTypesEdit ),
  [ADMIN_OVERRIDABLE_AT_TYPES_CHOOSER_SET_IS_ENABLED] : payloadOnly(overridableAtTypesChooserSetIsEnabled),
  [ADMIN_SEMESTER_RULE_EDIT] : payloadOnly(semesterRuleEdit),
  [ADMIN_SEMESTER_RULE_FETCH_BY_FACILITY_ID_RESPONSE] : payloadOnly(semesterRuleFetchByFaciltyIdResponse),
  [ADMIN_SEMESTER_RULE_EDIT_DONE] : payloadOnly(semesterRuleEditDone),
  [ADMIN_SEMESTER_RULE_EDIT_SAVE_RESPONSE] : payloadOnly(semesterRuleEditSaveResponse),
  [ADMIN_ATTENDANCE_TERM_SET_IS_COUNT_RESET_ENABLED] : payloadOnly(attendanceTermSetIsCountResetEnabled),
  [ADMIN_REMOVE_SKYWARD_STATE] : payloadOnly(adminRemoveSkywardState),
  
}

export default AdminReducer
