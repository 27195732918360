import React from "react"
import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync'
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import HelpIcon from '@material-ui/icons/Help'
import DoneIcon from '@material-ui/icons/Done'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'


import {SYNC_STATE_ERROR, SYNC_STATE_SYNCING, SYNC_STATE_NEEDS_SYNC, SYNC_STATE_NOT_SYNCABLE, SYNC_STATE_SYNCED_PULL, SYNC_STATE_SYNCED_PUSH, SYNC_STATE_NEEDS_DELETE} from "redux/constants";

class PassiveIconButton extends React.PureComponent {
  render() {
      return <IconButton style={{cursor:'default'}} {...this.props} ></IconButton>
  }
}

class SyncStatusIcon extends React.PureComponent {
  render() {
    const {syncState, titleExtra=''} = this.props
    switch (syncState) {
      case SYNC_STATE_SYNCING :
        return <PassiveIconButton title={`Syncing ${titleExtra}`}><SyncIcon /></PassiveIconButton>
      case SYNC_STATE_NEEDS_SYNC :
        return <PassiveIconButton title={`Needs sync ${titleExtra}`}><HourglassEmptyIcon /></PassiveIconButton>
      case SYNC_STATE_NEEDS_DELETE :
          return <PassiveIconButton title={`Needs reconcile with Skyward ${titleExtra}`}><HourglassEmptyIcon /></PassiveIconButton>
      case SYNC_STATE_SYNCED_PULL :
        return <PassiveIconButton title={`Synced PULL ${titleExtra}`}><DoneOutlineIcon/></PassiveIconButton>
      case SYNC_STATE_SYNCED_PUSH :
        return <PassiveIconButton title={`Synced PUSH ${titleExtra}`}><DoneIcon/></PassiveIconButton>
      case SYNC_STATE_NOT_SYNCABLE :
        return <PassiveIconButton title={`Not eligible for sync ${titleExtra}`}><SyncDisabledIcon/></PassiveIconButton>
      case SYNC_STATE_ERROR :
        return <PassiveIconButton title={`Error syncing ${titleExtra}`}><SyncProblemIcon/></PassiveIconButton>
      default :
        return <PassiveIconButton title={`Sync state (${syncState})`}><HelpIcon/></PassiveIconButton>
    }
  }
}

export default SyncStatusIcon